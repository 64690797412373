import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

const getDeviceConfig = (width: number): Breakpoints => {
  if (width < 320) {
    return Breakpoints.xs;
  }

  if (width >= 320 && width < 720) {
    return Breakpoints.sm;
  }

  if (width >= 720 && width < 1024) {
    return Breakpoints.md;
  }

  if (width >= 1024) {
    return Breakpoints.lg;
  }
};

const useBreakpoint = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    // calcInnerWidth();
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
