import gql from 'graphql-tag';
import User from '@graphql/fragments/user.gql';

import { useMutation } from '@apollo/react-hooks';
export const LOGIN_SOCIAL = gql`
  mutation LoginSocial(
    $accessToken: String!
    $type: String!
    $cartItems: [AddCartMultipleItem]
  ) {
    loginSocial(accessToken: $accessToken, type: $type, cartItems: $cartItems) {
      ...User
    }
  }

  ${User}
`;

export const useLoginSocialMutation = () => {
  return useMutation(LOGIN_SOCIAL);
};
