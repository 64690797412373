import { useCallback, useState, useEffect } from 'react';
import get from 'lodash/get';
import { ICheckoutContext } from '../context';
import {
  useCheckoutQuery,
  useAddCartItemMutation,
  useDeleteCartItemMutation,
  useUpdateCartItemMutation,
  useCalculateDeliveryFeeLazyQuery,
} from 'src/generated/graphql';

import { getKey, setKey } from './local-storage';
const LOCAL_CART_NAME = 'vvpfast-local-cart';
const LOCAL_GIFT_NAME = 'vvpfast-select-gift';
const MAXIMUM_ITEM = 150;

const useLocalAddCartItemMutation = ({ onCompleted }) => {
  const execute = useCallback(
    async (data) => {
      const { id, quantity } = data;

      let productInCart = [];
      let isInCart = false;

      const cart = getKey(LOCAL_CART_NAME, []);
      console.log('Storage:', cart);

      if (Array.isArray(cart)) {
        productInCart = cart.map((item) => {
          if (item.id === parseInt(id as string)) {
            item.quantity += quantity;
            isInCart = true;
          }

          return item;
        });
      }

      if (!isInCart) {
        productInCart.push({
          id,
          quantity,
        });
      }

      if (productInCart.length > MAXIMUM_ITEM) {
        throw Error(
          'Vượt quá số lượng sản phẩm trong giỏ hàng. Tối đa là 150 sản phẩm'
        );
      }

      setKey(LOCAL_CART_NAME, productInCart);
      onCompleted(productInCart);

      return productInCart;
    },
    [getKey, onCompleted]
  );

  return [execute];
};

const useLocalUpdateCartItemMutation = ({ onCompleted }) => {
  const execute = useCallback(
    async (data) => {
      const { id, quantity } = data;

      let productInCart = [];
      const cart = getKey(LOCAL_CART_NAME, []);
      console.log('Storage:', cart);

      if (Array.isArray(cart)) {
        productInCart = cart.map((item) => {
          if (item.id === parseInt(id as string)) {
            item.quantity = quantity;
          }

          return item;
        });
      }

      // const verifyProduct = await getProductDetail(id, client);
      // const availableQuantity = parseInt(_get(verifyProduct, 'data.product.quantity'));
      // if (availableQuantity < quantity) {
      //   throw Error('Sản phẩm không đủ số lượng');
      // }

      setKey(LOCAL_CART_NAME, productInCart);
      onCompleted(productInCart);

      return productInCart;
    },
    [getKey, onCompleted]
  );

  return [execute];
};

const useLocalDeleteCartItemMutation = ({ onCompleted }) => {
  const execute = useCallback(
    async (data) => {
      const { id } = data;

      let productInCart = [];
      const cart = getKey(LOCAL_CART_NAME, []);
      console.log('Storage:', cart);

      if (Array.isArray(cart)) {
        productInCart = cart.filter(
          (item: any) => item.id !== parseInt(id as string)
        );
      }

      setKey(LOCAL_CART_NAME, productInCart);
      onCompleted(productInCart);

      return productInCart;
    },
    [getKey, onCompleted]
  );

  return [execute];
};

const useLocalCleanCartMutation = () => {
  const execute = useCallback(() => {
    setKey(LOCAL_CART_NAME, []);
  }, [setKey]);

  return [execute];
};

export default function createCheckoutContext({
  authenticated,
}): ICheckoutContext {
  const { data, loading, error, refetch } = useCheckoutQuery({
    ssr: false,
    variables: { input: { source: getKey(LOCAL_CART_NAME, []) } },
    onCompleted: () => {},
    onError: () => {},
  });

  const handleRefetchCart = async () => {
    return refetch({ input: { source: getKey(LOCAL_CART_NAME, []) } });
  };

  const [handleRemoteAdd] = useAddCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });
  const [handleRemoteUpdate] = useUpdateCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });
  const [handleRemoteDelete] = useDeleteCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });

  const [handleLocalAdd] = useLocalAddCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });
  const [handleLocalUpdate] = useLocalUpdateCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });
  const [handleLocalDelete] = useLocalDeleteCartItemMutation({
    onCompleted: (data) => {
      handleRefetchCart();
    },
  });

  const handleAddItem = useCallback(
    async (data) => {
      if (authenticated) {
        return await handleRemoteAdd({ variables: data });
      }

      return await handleLocalAdd(data);
    },
    [authenticated]
  );

  const handleUpdateItem = useCallback(
    async (data) => {
      if (authenticated) {
        return await handleRemoteUpdate({ variables: data });
      }

      return await handleLocalUpdate(data);
    },
    [authenticated]
  );

  const handleDeleteItem = useCallback(
    async (data) => {
      if (authenticated) {
        return await handleRemoteDelete({ variables: data });
      }

      return await handleLocalDelete(data);
    },
    [authenticated]
  );

  const [handleCleanCart] = useLocalCleanCartMutation();
  const [selectedGift, setSelectGift] = useState(null);
  useEffect(() => {
    const gift = getKey(LOCAL_GIFT_NAME, null);
    setSelectGift(gift);
  }, []);

  const handleChooseGift = useCallback((newGift) => {
    setSelectGift(newGift);
    setKey(LOCAL_GIFT_NAME, newGift);
  }, []);
  const handleCleanGift = useCallback(() => {
    setSelectGift(null);
    setKey(LOCAL_GIFT_NAME, {});
  }, []);

  const [
    fetchCalculateDeliveryFee,
    { data: calculateDeliveryFee },
  ] = useCalculateDeliveryFeeLazyQuery({
    fetchPolicy: 'network-only'
  });
  const handleCalculateDelivery = useCallback(
    (districtId: string) => {
      const totalProductPrice = get(
        data,
        'checkout.promotion.totalProductPrice',
        0
      );
      const totalWeight = data.checkout.cart.reduce((prev, next) => {
        return get(next, 'totalWeight') + prev;
      }, 0);

      fetchCalculateDeliveryFee({
        variables: {
          input: {
            districtId,
            totalWeight,
            totalProductPrice,
          },
        },
      });
    },
    [data]
  );

  return {
    data,
    loading,
    error,
    selectedGift,
    calculateDeliveryFee,
    handleRefetchCart,
    handleAddItem,
    handleUpdateItem,
    handleDeleteItem,
    handleCleanCart,
    handleChooseGift,
    handleCleanGift,
    handleCalculateDelivery,
  };
}
