import React from 'react';
import dynamic from 'next/dynamic';
import Placeholder from './placeholder';

const Footer = dynamic(() => import('./index'), {
  ssr: false,
  loading: ({ error, isLoading, pastDelay }) =>
    pastDelay ? <Placeholder /> : null,
});

export default Footer;
