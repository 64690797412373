import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';

export type THeader = TParentComponentProps & {
  id?: string;
  fixed?: boolean;
  loaded?: boolean;
};

export const Header: FunctionComponent<THeader> = ({
  children,
  fixed,
  loaded,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'header--fixed': fixed,
    loaded: loaded,
    [className]: !!className,
  });

  return (
    <ParentComponent className={cx} {...otherProps}>
      {children}
    </ParentComponent>
  );
};

Header.defaultProps = {
  as: 'header',
  defaultClassName: 'header',
  fixed: false,
};

export default Header;
export { default as HeaderTop } from 'src/views/components/header/HeaderTop';
export { default as SearchForm } from 'src/views/components/header/SearchForm';
export { default as IndicatorCart } from 'src/views/components/header/IndicatorCart';
export { default as IndicatorSignIn } from 'src/views/components/header/IndicatorSignIn';
