import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TProductContentProps = TParentComponentProps & {};

export const ProductContent: FunctionComponent<TProductContentProps> = (
  props
) => {
  return <ParentComponent {...props} />;
};

ProductContent.defaultProps = {
  defaultClassName: 'product__content',
};

export default ProductContent;
