import React, {
  FunctionComponent,
  Fragment,
  useState,
  useCallback,
  memo,
} from 'react';
import { Form, Button, Spinner, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useTimeout from '@restart/hooks/useTimeout';
import { DELAY } from '@helpers/const';
import { MODAL_FORM, WARNING_CART_TEXT } from '../intl';
import { useMutation } from '@apollo/react-hooks';
import { LOGIN } from '@graphql/mutation/user';
import { ME } from '@views/modules/header/hooks/use-auth';
import _get from 'lodash/get';

import useLayoutContext from '@views/layouts/hooks/use-context';

import SocialLogin from './social-login';

interface IValues {
  email: string;
  password: string;
}

const INITIAL_VALUES: IValues = {
  email: '',
  password: '',
};

const LOGIN_SCHEMA = Yup.object().shape({
  password: Yup.string().required(MODAL_FORM.REQUIRED),
  email: Yup.string()
    .email(MODAL_FORM.EMAIL_INVALID)
    .required(MODAL_FORM.REQUIRED),
});

interface LoginProps {
  onClose: () => void;
  onGotoSignUp: () => void;
  onForgetPassword: () => void;
}

export const Login: FunctionComponent<LoginProps> = ({
  onClose,
  onGotoSignUp,
  onForgetPassword,
}) => {
  const [fetchLogin] = useMutation(LOGIN);

  const [isFetchError, setIsFetchError] = useState(false);
  const [fetchMessage, setFetchMessage] = useState(null);

  const {
    checkout: { data, handleRefetchCart, handleCleanCart },
  } = useLayoutContext();

  const timeout = useTimeout();
  const clearFetchError = useCallback(() => {
    setFetchMessage(null);
    setIsFetchError(false);
  }, [isFetchError]);

  const setFetchError = useCallback((message) => {
    setFetchMessage(message);
    setIsFetchError(true);
  }, []);

  const onSubmit = async (values: IValues, { setSubmitting }) => {
    try {
      let cartItems = null;
      const cart = _get(data, 'checkout.cart', []);
      if (Array.isArray(cart) && cart.length > 0) {
        cartItems = cart.map((item) => ({
          id: _get(item, 'id', 0),
          quantity: _get(item, 'quantity', 0),
        }));
      }

      await fetchLogin({
        variables: {
          ...values,
          cartItems,
        },
        update: async (cache: any, { data: { login } }) => {
          await handleRefetchCart();

          cache.writeQuery({
            query: ME,
            data: { me: login },
          });

          handleCleanCart();
          setSubmitting(false);
          onClose();
        },
      });
    } catch (error) {
      setFetchError(
        _get(error, 'graphQLErrors[0].message', MODAL_FORM.WRONG_LOGIN)
      );
      timeout.set(clearFetchError, DELAY);
    }
  };

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={LOGIN_SCHEMA}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="form form--sign">
          {isFetchError && (
            <Alert variant="danger" className="mb-4">
              {fetchMessage}
            </Alert>
          )}

          <Form.Group>
            <Form.Control
              required
              type="email"
              placeholder={MODAL_FORM.EMAIL}
              name="email"
              value={values.email}
              onChange={handleChange}
              isInvalid={!!errors.email && !!touched.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Control
              required
              type="password"
              placeholder={MODAL_FORM.PASSWORD}
              name="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={!!errors.password && !!touched.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="d-flex">
            <Button
              type="submit"
              variant="primary"
              className="flex-fill d-flex justify-content-center align-items-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                  />
                  {MODAL_FORM.SUBMITTING}
                </Fragment>
              ) : (
                <Fragment>{MODAL_FORM.LOGIN}</Fragment>
              )}
            </Button>
          </div>
          <div className="reset-password">
            <a className="text-primary" onClick={onForgetPassword}>
              {MODAL_FORM.FORGET_PASSWORD}
            </a>
          </div>
          <div className="switch-mode">
            {MODAL_FORM.HAS_ACCOUNT_YET}{' '}
            <a className="text-primary" onClick={onGotoSignUp}>
              {MODAL_FORM.SIGN_UP}
            </a>
          </div>

          <SocialLogin onClose={onClose} />
        </Form>
      )}
    </Formik>
  );
};

export default memo(Login);
