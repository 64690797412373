import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';

export type THeader = TParentComponentProps & {
  id?: string;
  top?: boolean;
};

export const Navbar: FunctionComponent<THeader> = ({
  top,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'navbar--top': top,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

Navbar.defaultProps = {
  as: 'section',
  defaultClassName: 'navbar',
  top: false,
};

export default Navbar;
