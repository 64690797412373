import React, { ReactNode, Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export class CardNewPrice extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product-card__new-price',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class CardBadgeSale extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product-card__badge-sale',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class CardOldPrice extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product-card__old-price',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export type TProductCardPriceProps = {
  className?: string;
  children?: ReactNode;
};

export class CardPrice extends Component<TParentComponentProps> {
  static NewPrice = CardNewPrice;
  static BadgeSale = CardBadgeSale;
  static OldPrice = CardOldPrice;
  static defaultProps = {
    defaultClassName: 'product-card__prices',
  };

  render(): JSX.Element {
    const { children, ...otherProps } = this.props;

    return <ParentComponent {...otherProps}>{children}</ParentComponent>;
  }
}

export default CardPrice;
