import React, { FC } from 'react';
import Skeleton from '@views/components/common/Skeleton';
import ParentComponent from '@views/components/common/ParentComponent';
import CardBody from './CardBody';
import CardInfo from './CardInfo';
import CardImage from './CardImage';
import CardPrice from './CardPrice';
// import LazyImage, { placeholder } from '@views/components/lazy-image';

export const CardSkeleton: FC = (): JSX.Element => {
  return (
    <ParentComponent className="product-card" as="div">
      <CardImage>
        <Skeleton
          width="100%"
          height="150px"
          widthRandomness={0}
          borderRadius="8px"
        />
      </CardImage>
      <CardBody style={{ paddingBottom: '1rem' }}>
        <CardInfo>
          <CardPrice>
            <CardPrice.NewPrice>
              <Skeleton width="100%" height="15px" widthRandomness={0} />
            </CardPrice.NewPrice>
            <CardPrice.NewPrice>
              <Skeleton width="80%" height="15px" widthRandomness={0} />
            </CardPrice.NewPrice>
          </CardPrice>
        </CardInfo>
      </CardBody>
    </ParentComponent>
  );
};

export default CardSkeleton;
