import React, { Fragment } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import styled from 'styled-components';

import { INTERNAL_ROUTES } from '@helpers/route';
import { BlockSidebar } from '@views/components/block/Block';
import Account from './components/account';

import { MENU_LIST } from './intl';
import IconAccount from '@views/static/icon-account.svg';
import IconAddress from '@views/static/icon-location.svg';
import IconOrder from '@views/static/icon-order.svg';
import IconHeart from '@views/static/icon-heart.svg';
import IconNotification from '@views/static/icon-notification.svg';

const IconWrapper = styled.div`
  svg {
    width: 22px;
    height: 22px;
    margin-right: 0.5rem;
  }
`;

const LIST = [
  {
    id: 'my-account',
    content: (
      <Fragment>
        <IconWrapper>
          <IconAccount />
          {MENU_LIST.MY_ACCOUNT}
        </IconWrapper>
      </Fragment>
    ),
    route: INTERNAL_ROUTES.ACCOUNT,
  },
  {
    id: 'manage-address',
    content: (
      <Fragment>
        <IconWrapper>
          <IconAddress />
          {MENU_LIST.MANAGE_ADDRESS}
        </IconWrapper>
      </Fragment>
    ),
    route: INTERNAL_ROUTES.MANAGE_ADDRESS,
  },
  {
    id: 'my-order',
    content: (
      <Fragment>
        <IconWrapper>
          <IconOrder />
          {MENU_LIST.ORDER}
        </IconWrapper>
      </Fragment>
    ),
    route: INTERNAL_ROUTES.MANAGE_ORDER,
  },
  {
    id: 'interest-order',
    content: (
      <Fragment>
        <IconWrapper>
          <IconHeart />
          {MENU_LIST.INTEREST_PRODUCT}
        </IconWrapper>
      </Fragment>
    ),
    route: INTERNAL_ROUTES.INTEREST_PRODUCT,
  },
  {
    id: 'notification',
    content: (
      <Fragment>
        <IconWrapper>
          <IconNotification />
          {MENU_LIST.NOTIFICATION}
        </IconWrapper>
      </Fragment>
    ),
    route: INTERNAL_ROUTES.NOTIFICATION,
  },
];

export const Sidebar = () => {
  const router = useRouter();

  return (
    <BlockSidebar>
      <Account />
      <BlockSidebar.Body>
        <ListGroup className="list-group--transparent">
          {LIST.map((item) => (
            <Link key={item.id} href={item.route} passHref>
              <ListGroupItem
                as="a"
                active={router.pathname.indexOf(item.route) !== -1}
              >
                {item.content}
              </ListGroupItem>
            </Link>
          ))}
        </ListGroup>
      </BlockSidebar.Body>
    </BlockSidebar>
  );
};

export default Sidebar;
