import React, { memo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import HeaderWrapper from '@views/components/header/Header';
import HeaderMain from './sub-modules/main';
import HeaderTop from './sub-modules/top';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';
// import cn from 'classnames';
// import * as S from './styles';

const HeaderContainer = (): JSX.Element => {
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);
  const [isFixed, setIsFixed] = useState(false);

  const handleEnterBanner = () => {
    setIsFixed(false);
  };

  const handlerLeaveBanner = () => {
    setIsFixed(true);
  };

  return (
    <>
      <HeaderWrapper id="header" fixed={isFixed}>
        {/* <S.Wrapper
        id="header"
        className={cn('header', { 'header--fixed': isFixed })}
        fixed={isFixed}
      > */}
        {!isFixed && <HeaderTop />}
        <HeaderMain />
        {/* </S.Wrapper> */}
      </HeaderWrapper>
      {!isMobile && (
        <Waypoint onEnter={handleEnterBanner} onLeave={handlerLeaveBanner} />
      )}
    </>
  );
};

export default memo(HeaderContainer);
