import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockSidebarHeader: FunctionComponent<TParentComponentProps> = (
  props
) => {
  return <ParentComponent {...props} />;
};

BlockSidebarHeader.defaultProps = {
  defaultClassName: 'block-sidebar__header',
};

export default BlockSidebarHeader;
