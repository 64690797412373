import { useEffect, useState } from 'react';

import loadScript from '@helpers/load-script';
import removeScript from '@helpers/remove-script';
import { stringify, parse } from 'query-string';
import _get from 'lodash/get';

export enum FBResponseStatus {
  Connected = 'connected',
  NotAuth = 'not_authorized',
  Unknown = 'unknown',
}

export interface IFBResponse {
  status: 'connected' | 'not_authorized' | 'unknown';
  authResponse?: {
    accessToken: string;
    expiresIn: string;
    reauthorize_required_in: string;
    signedRequest: string;
    userID: string;
  };
}
export interface IProps {
  version: string;
  xfbml: boolean;
  cookie: boolean;
  scope: string;
  customerChat: boolean;
  redirectUri?: string;
  appId: string;
  onResponse: (response: IFBResponse) => void;
}

export const useFacebookLogin = (props: IProps) => {
  const {
    version = 'v3.2',
    xfbml = true,
    cookie = true,
    scope = 'public_profile,email',
    customerChat = false,
    redirectUri,
    appId,
    onResponse,
  } = props;

  const [loaded, setLoaded] = useState(false);
  const isRedirectedFromFb = () => {
    const params = parse(window.location.search);
    return (
      _get(params, 'state') === 'facebookdirect' &&
      (_get(params, 'code') || _get(params, 'granted_scopes'))
    );
  };

  const getIsMobile = () => {
    let isMobile = false;

    try {
      isMobile = !!(
        _get(window, 'navigator.standalone') ||
        navigator.userAgent.match('CriOS') ||
        navigator.userAgent.match(/mobile/i)
      );
    } catch (ex) {
      // continue regardless of error
    }

    return isMobile;
  };

  const signIn = () => {
    if (typeof FB !== undefined) {
      if (getIsMobile()) {
        const params = {
          client_id: appId,
          redirect_uri: redirectUri || location.href,
          state: 'facebookdirect',
          return_scopes: false,
          scope,
          response_type: 'code',
          auth_type: '',
        };

        location.href = `https://www.facebook.com/v3.2/dialog/oauth?${stringify(
          params
        )}`;

        return;
      }

      // FB.getLoginStatus((response: IFBResponse) => {
      //   if (response.status === FBResponseStatus.Connected) {
      //     onResponse(response);
      //   } else {

      //   }
      // });
      const fbResponse = FB.getAuthResponse();
      if (!!fbResponse) {
        onResponse(fbResponse as IFBResponse);

        return;
      }

      FB.login(
        (loginResponse: IFBResponse) => {
          onResponse(loginResponse);
        },
        { scope: 'public_profile,email' }
      );
    }
  };

  // useEffect(() => {
  //   if (document.getElementById('facebook-sdk')) {
  //     setLoaded(true);
  //     return;
  //   }

  //   window.fbAsyncInit = () => {
  //     FB.init({
  //       version,
  //       appId,
  //       xfbml,
  //       cookie,
  //     });
  //     FB.AppEvents.logPageView();
  //     if (isRedirectedFromFb()) {
  //       FB.getLoginStatus((response: IFBResponse) => {
  //         onResponse(response);
  //       });
  //     }
  //   };
  //   loadScript('facebook-sdk', 'https://connect.facebook.net/vi_VN/sdk.js', () => {
  //     setLoaded(true);
  //   });

  //   if (!!customerChat) {
  //     loadScript('facebook-customer-sdk', 'https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js', () => {});
  //   }

  //   return () => {
  //     removeScript('facebook-sdk');
  //     removeScript('facebook-customer-sdk');
  //   }
  // }, []);

  return [loaded, signIn];
};
