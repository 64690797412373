import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TBlockFormProps = TParentComponentProps & {
  morseSpace?: boolean;
};

export const BlockForm: FunctionComponent<TBlockFormProps> = ({
  morseSpace,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'block-form--more-spacing': morseSpace,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

BlockForm.defaultProps = {
  defaultClassName: 'block-form',
};

export default BlockForm;
