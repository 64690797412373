import React, { Component } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import { BlockHeader } from './BlockHeader';
import { BlockBody } from './BlockBody';
import { BlockTitle } from './BlockTitle';
import { BlockLink } from './BlockLink';
import { BlockHtml } from './BlockHtml';
import { BlockOverview } from './BlockOverview';

export type TBlockProps = TParentComponentProps & {
  isSection?: boolean;
  isFlexCenter?: boolean;
  isFixedHeight?: boolean;
};

export class Block extends Component<TBlockProps> {
  static Header = BlockHeader;
  static Body = BlockBody;
  static Title = BlockTitle;
  static Link = BlockLink;
  static Html = BlockHtml;
  static Overview = BlockOverview;
  static defaultProps = {
    defaultClassName: 'block',
    isSection: false,
    isFlexCenter: false,
    isFixedHeight: false,
  };

  render(): JSX.Element {
    const {
      className,
      isSection,
      isFlexCenter,
      isFixedHeight,
      ...otherProps
    } = this.props;
    const blockClassName = classNames({
      'block--section': isSection,
      'block--fixed-height': isFixedHeight,
      'd-flex flex-wrap justify-content-center align-items-center': isFlexCenter,
      [className]: !!className,
    });

    return <ParentComponent className={blockClassName} {...otherProps} />;
  }
}

export default Block;
export { default as BlockHeader } from './BlockHeader';
export { default as BlockTitle } from './BlockTitle';
export { default as BlockLink } from './BlockLink';
export { default as BlockProduct } from './BlockProduct';
export { default as BlockProductBody } from './BlockProductBody';
export { default as BlockProductList } from './BlockProductList';
export { default as BlockProductListItem } from './BlockProductListItem';
export { default as BlockProductListSkeleton } from './BlockProductListSkeleton';
export { default as BlockSidebar } from './BlockSidebar';
export { default as BlockSidebarPlaceholder } from './BlockSidebarPlaceholder';
