import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockLink: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

BlockLink.defaultProps = {
  defaultClassName: 'block__link',
  as: 'a',
};

export default BlockLink;
