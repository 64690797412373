export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  });
};

export const scrollTo = (ref: any, position = 0) => {
  window.scrollTo(position, ref.current.offsetTop);
};

export default scrollTop;
