import ApolloClient from 'apollo-client';

const initState = {
  show: false,
  type: null,
  __typename: 'AuthForm',
};

const resolvers = {
  Query: {
    getAuthForm: (_: any, args: any, { cache }) => {
      return initState;
    },
  },

  Mutation: {
    setAuthForm: (_: any, args: any, { cache }) => {
      cache.writeData({
        data: {
          getAuthForm: {
            ...args,
            __typename: 'AuthForm',
          },
        },
      });

      return null;
    },
  },
};

export const registerResolver = (client: any) => {
  client.addResolvers(resolvers);
};
