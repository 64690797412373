import styled from 'styled-components';

export const Wrapper = styled.section`
  svg,
  .header__icon {
    fill: var(--white);
    height: 20px;
    margin-right: 0.25rem;
    object-fit: contain;
    width: 20px;
  }
`;
