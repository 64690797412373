import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TBlockHeaderProps = TParentComponentProps & {
  tabletColumn?: boolean;
};

export const BlockHeader: FunctionComponent<TBlockHeaderProps> = ({
  tabletColumn,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'block__header--tablet-column': tabletColumn,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

BlockHeader.defaultProps = {
  defaultClassName: 'block__header',
};

export default BlockHeader;
