import React, { ReactNode, Component } from 'react';
import classNames from 'classnames';
import RatingBody from './RatingBody';
import RatingItem from './RatingItem';

export type TRatingProps = {
  className?: string;
  children?: ReactNode;
};

export class Rating extends Component<TRatingProps> {
  static Body = RatingBody;
  static Item = RatingItem;

  render(): JSX.Element {
    const { className, children } = this.props;

    const cx = classNames({
      rating: true,
      [className]: !!className,
    });

    return <div className={cx}>{children}</div>;
  }
}

export { RatingBody, RatingItem };
export default Rating;
