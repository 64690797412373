import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TBlockProductList = TParentComponentProps & {
  withSidebar?: boolean;
};

export const BlockProductList: FunctionComponent<TBlockProductList> = ({
  withSidebar,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'block-product__list--with-sidebar': withSidebar,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

BlockProductList.defaultProps = {
  defaultClassName: 'block-product__list',
};

export default BlockProductList;
