import React from 'react';
import { useLayoutContext } from '@views/layouts';
import styled from 'styled-components';

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.53);
  z-index: 998;
`;

const Container = () => {
  const { overlay } = useLayoutContext();

  if (overlay) {
    return <Overlay />;
  }

  return null;
};

export default Container;
