import React, { useState, useEffect } from 'react';
// import classNames from 'classnames';

export const Transition = ({ children }) => {
  const [className, setClassName] = useState(
    'slide-fade-leave-active slide-fade-leave-to'
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setClassName('slide-fade-enter-active slide-fade-enter-to');
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return <div className={className}>{children}</div>;
};

export default Transition;
