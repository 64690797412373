import React, { ReactNode, Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export class ProductNewPrice extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product__new-price',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class ProductBadgeSale extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product__badge-sale',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class ProductOldPrice extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product__old-price',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class ProductPrice extends Component<TParentComponentProps> {
  static NewPrice = ProductNewPrice;
  static BadgeSale = ProductBadgeSale;
  static OldPrice = ProductOldPrice;
  static defaultProps = {
    defaultClassName: 'product__prices',
  };

  render(): JSX.Element {
    const { children, ...otherProps } = this.props;

    return <ParentComponent {...otherProps}>{children}</ParentComponent>;
  }
}

export default ProductPrice;
