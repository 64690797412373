import React, { Component } from 'react';
import cx from 'classnames';

import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import BlockProductBody from './BlockProductBody';
import BlockProductList from './BlockProductList';
import BlockProductListItem from './BlockProductListItem';

export class BlockProduct extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'block-product',
  };
  static Body = BlockProductBody;
  static List = BlockProductList;
  static Item = BlockProductListItem;

  render(): JSX.Element {
    const { className, v2, v3, ...rest } = this.props;
    const classes = cx(
      {
        'block-product--v2': !!v2,
        'block-product--v3': !!v3,
      },
      className
    );

    return <ParentComponent className={classes} {...rest} />;
  }
}
export default BlockProduct;
