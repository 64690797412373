import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockProductListItem: FunctionComponent<TParentComponentProps> = (
  props
) => <ParentComponent {...props} />;

BlockProductListItem.defaultProps = {
  defaultClassName: 'block-product__list-item',
};

export default BlockProductListItem;
