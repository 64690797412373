import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TProductNameProps = TParentComponentProps & {};

export const ProductRating: FunctionComponent<TProductNameProps> = (props) => {
  return <ParentComponent {...props} />;
};

ProductRating.defaultProps = {
  defaultClassName: 'product__rating',
};

export default ProductRating;
