import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import LazyImage from '../lazy-image';

export type TRatingItemProps = {
  isActive?: boolean;
};

export const cx = (isActive: boolean) =>
  classNames({
    rating__star: true,
    'rating__star--active': !!isActive,
  });

export const RatingItem: FunctionComponent<TRatingItemProps> = ({
  isActive = false,
}) => {
  if (isActive) {
    return (
      // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" className={cx(isActive)}>
      //   <path fill="#f0d505" d="M7 .3L9 4h3.5c.4.1.6.8.3 1.1L10 7.4l1 4c.1.4-.4.8-.8.6l-3.7-2-3.7 1.9c-.4.2-.9-.1-.8-.6l1-4L.2 5.1C-.2 4.8 0 4.1.5 4H4L6 .3c.2-.4.8-.4 1 0z"></path>
      //   <path fill="#f0d505" d="M6.5 1.5l1.6 3 .3.5h3l-2 1.6-.5.4.1.6.7 3L7 9.1l-.5-.2-.5.2-2.8 1.5.7-3 .2-.6-.5-.4-2-1.6h3l.3-.5 1.6-3m0-1.5c-.2 0-.4.1-.5.3L4 4H.5c-.5.1-.7.8-.3 1.1L3 7.4l-1 4c-.1.3.2.6.5.6.1 0 .2 0 .3-.1L6.5 10l3.7 1.9c.1 0 .2.1.3.1.3 0 .6-.3.5-.6l-1-4 2.8-2.3c.3-.3.1-1-.3-1.1H9L7 .3C6.9.1 6.7 0 6.5 0z"></path>
      // </svg>
      <LazyImage
        src="/assets/icon/icon-star-yellow.svg"
        alt="rating"
        className={cx(isActive)}
      />
    );
  }

  return (
    // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 12" className={cx(isActive)}>
    //   <path d="M7 .3L9 4h3.5c.4.1.6.8.3 1.1L10 7.4l1 4c.1.4-.4.8-.8.6l-3.7-2-3.7 1.9c-.4.2-.9-.1-.8-.6l1-4L.2 5.1C-.2 4.8 0 4.1.5 4H4L6 .3c.2-.4.8-.4 1 0z"></path>
    //   <path d="M6.5 1.5l1.6 3 .3.5h3l-2 1.6-.5.4.1.6.7 3L7 9.1l-.5-.2-.5.2-2.8 1.5.7-3 .2-.6-.5-.4-2-1.6h3l.3-.5 1.6-3m0-1.5c-.2 0-.4.1-.5.3L4 4H.5c-.5.1-.7.8-.3 1.1L3 7.4l-1 4c-.1.3.2.6.5.6.1 0 .2 0 .3-.1L6.5 10l3.7 1.9c.1 0 .2.1.3.1.3 0 .6-.3.5-.6l-1-4 2.8-2.3c.3-.3.1-1-.3-1.1H9L7 .3C6.9.1 6.7 0 6.5 0z"></path>
    // </svg>
    <LazyImage
      src="/assets/icon/icon-star.svg"
      alt="rating"
      className={cx(isActive)}
    />
  );
};

export default RatingItem;
