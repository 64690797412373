import { Record, fromJS } from 'immutable';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_AUTH_FORM_QUERY,
  SET_AUTH_FORM_QUERY,
} from '../data/auth-form.graphql';

import { GetAuthFormProps, FormType } from '../data/auth-form.interface';

export const useAuthFormQuery = () => {
  const { data, ...otherProps } = useQuery(GET_AUTH_FORM_QUERY);

  const getAuthForm = !!data ? fromJS(data.getAuthForm) : fromJS({});

  return {
    getAuthForm,
    ...otherProps,
  };
};

export const useAuthFormMutation = () => {
  return useMutation(SET_AUTH_FORM_QUERY);
};

export const useLoginMutation = () => {
  return useMutation(SET_AUTH_FORM_QUERY, {
    variables: {
      show: true,
      type: FormType.Login,
    },
  });
};

export const useSignupMutation = () => {
  return useMutation(SET_AUTH_FORM_QUERY, {
    variables: {
      show: true,
      type: FormType.Signup,
    },
  });
};

export const useForgetPasswordMutation = () => {
  return useMutation(SET_AUTH_FORM_QUERY, {
    variables: {
      show: true,
      type: FormType.Forgot,
    },
  });
};

export const useCloseMutation = () => {
  return useMutation(SET_AUTH_FORM_QUERY, {
    variables: {
      show: false,
      type: null,
    },
  });
};
