import React, { Fragment } from 'react';
import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import Skeleton from '@views/components/common/Skeleton';
import Product from '@views/components/product/Product';
import Block from '../block/Block';

const ListItem = styled.div`
  & > span {
    display: flex;
    justify-content: center;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    & > span {
      margin: 0.25rem;
      flex: 1 0;
    }
  }
`;

const ListRating = styled.div`
  height: 18px;
  & > span {
    display: flex;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    & > span {
      margin: 0 0.25rem;
      border-radius: 50%;
    }
  }
`;
const OldPrice = styled.div`
  flex: 0 0 100%;
  & > span {
    display: flex;
    height: 20px;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
    & > span {
      margin: 0 0.25rem;
      flex: 1 0;
    }
  }
`;
const ListSidebar = styled.div`
  margin-bottom: 1.25rem;
  & > span {
    & > span {
      margin-bottom: 0.25rem;
    }
  }
`;

const Title = styled.div`
  flex: 0 0 100%;
`;

export const Placeholder = () => (
  <Fragment>
    <Block isSection>
      <Product standard>
        <Product.Content>
          <div className="product__gallery">
            <Skeleton width="100%" height="300px" widthRandomness={0} />
            <ListItem>
              <Skeleton
                width="50px"
                height="50px"
                widthRandomness={0}
                count={4}
              />
            </ListItem>
          </div>
          <Product.Body>
            <Product.Info>
              <Product.Name>
                <Skeleton width="100%" height="50px" widthRandomness={0} />
              </Product.Name>
              <Product.Rating>
                <ListRating>
                  <Skeleton
                    width="18px"
                    height="18px"
                    widthRandomness={0}
                    count={5}
                  />
                </ListRating>
              </Product.Rating>
              {Array.from({ length: 1 }, (_, i) => Symbol(i).toString()).map(
                (key, i) => (
                  <Product.Price key={key}>
                    <Product.Price.NewPrice>
                      <Skeleton
                        width="100%"
                        height="25px"
                        widthRandomness={0}
                      />
                    </Product.Price.NewPrice>
                    <OldPrice>
                      <Skeleton
                        width="100%"
                        height="20px"
                        widthRandomness={0}
                        count={2}
                      />
                    </OldPrice>
                  </Product.Price>
                )
              )}
              <div className="mt-4">
                <Skeleton
                  width="100%"
                  height="1rem"
                  widthRandomness={0}
                  count={4}
                />
              </div>
            </Product.Info>
            <Product.Sidebar>
              <ListSidebar>
                <Skeleton
                  width="100%"
                  height="20px"
                  widthRandomness={0}
                  count={8}
                />
              </ListSidebar>
              <ListSidebar>
                <Skeleton
                  width="100%"
                  height="20px"
                  widthRandomness={0}
                  count={4}
                />
              </ListSidebar>
            </Product.Sidebar>
          </Product.Body>
        </Product.Content>
      </Product>
    </Block>
  </Fragment>
);

export default Placeholder;
