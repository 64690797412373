import React from 'react';
import Link from 'next/link';
import _get from 'lodash/get';

import LazyImage from '@views/components/lazy-image';
import categoryMap from 'src/cache/category-map';
import { useConfig } from '@server/lib/config';

import { INTERNAL_URI, categoryHref } from '@helpers/route';
import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

import * as S from './styles';

const SuggestCategory: React.FC<any> = () => {
  const { TOP_CATEGORIES } = useConfig();
  const categories = TOP_CATEGORIES.map((id) => categoryMap[id]).filter(
    (item) => !!item
  );
  if (categories.length === 0) {
    return null;
  }

  return (
    <S.CategoryWrapper>
      <S.Header>
        <span>Danh mục nổi bật</span>
      </S.Header>
      <S.CategoryList>
        {categories.map((category) => {
          return (
            <S.CategoryItem key={`category-item-${category.id}`}>
              <Link
                href={INTERNAL_URI.CATEGORY}
                as={categoryHref(category.id, category.urlKey)}
              >
                <a
                  aria-label={category.name}
                  title={category.name}
                  className={cn('search-item', EVENT_CLASS_NAME)}
                  data-category={EVENT_CATEGORY.CATEGORY_TOP_SEARCH}
                  data-action={EVENT_ACTION_NAME}
                  data-label={category.name}
                >
                  <LazyImage
                    src={
                      _get(category, 'imageIcon') ||
                      '/assets/art-and-design.svg'
                    }
                    placeholder="/assets/art-and-design.svg"
                    alt={_get(category, 'name')}
                    className="item-icon"
                  />
                  <div className="info">
                    <span className="title">{category.name}</span>
                  </div>
                </a>
              </Link>
            </S.CategoryItem>
          );
        })}
      </S.CategoryList>
    </S.CategoryWrapper>
  );
};

export default SuggestCategory;
