import React, {
  FunctionComponent,
  useState,
  useCallback,
  memo,
  Fragment,
} from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import AccountSidebar from '@views/modules/account-sidebar';
import { useLoginMutation } from '@views/modules/header/sub-modules/top/hooks/use-auth-form';
import { useLogoutMutation } from '@views/modules/header/sub-modules/top/hooks/use-logout';

import IndicatorCart from '../indicator-cart';
import { INDICATOR_TEXT } from '../../intl';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

export const IndicatorMobile: FunctionComponent<any> = ({
  authenticated,
  quantity,
}): JSX.Element => {
  const [show, setShow] = useState(false);

  const handleOpenSetting = useCallback(
    (event) => {
      event.preventDefault();
      setShow(true);
    },
    [show]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, [show]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchLogout] = useLogoutMutation();
  const handleLogout = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await fetchLogout({
        update: (cache: any) => {
          cache.writeData({
            data: {
              me: null,
              cart: null,
              orders: null,
              address: null,
            },
          });

          setIsSubmitting(false);
          // router.replace('/');
          location.href = '/';
        },
      });
    } catch (ex) {
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  const [openLoginForm] = useLoginMutation();
  const handleOpenLogin = useCallback((event) => {
    event.preventDefault();
    openLoginForm();
  }, []);

  return (
    <>
      <div className="navbar-action d-md-none">
        <IndicatorCart
          hide={false}
          quantity={quantity}
          dataLabel={EVENT_LABEL.ACTION_CART_MOBILE}
        />
        <div className="indicator">
          <a
            onClick={authenticated ? handleOpenLogin : handleOpenSetting}
            className={cn('indicator__button', EVENT_CLASS_NAME)}
            data-category={EVENT_CATEGORY.ACTION}
            data-action={EVENT_ACTION_NAME}
            data-label={EVENT_LABEL.ACTION_PROFILE_MOBILE}
          >
            <span className="indicator__area">
              <svg
                viewBox="0 0 28 28"
                xmlns="http://www.w3.org/2000/svg"
                className="indicator__icon indicator__icon--account"
              >
                <path
                  d="M10.8166 17.0039531c-1.5729-1.03425-2.6166-2.8161-2.6166-4.82895v-2.1c0-3.17625 2.5977-5.775 5.775-5.775 3.17625 0 5.775 2.59875 5.775 5.775v2.1c0 2.016-1.04685 3.801-2.625 4.8342v-.0042l6.18975 2.667c.3864.1659.63525.546.63525.9639v2.5641c0 .5775-.4725 1.05-1.05 1.05H5.05c-.5775 0-1.05-.4725-1.05-1.05v-2.5641c0-.4179.24885-.798.6342-.9639l6.1908-2.667-.0084-.00105z"
                  stroke="#FFF"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </span>
          </a>
        </div>
      </div>

      <Modal show={show} className="modal-mobile-full">
        <div className="modal__header">
          <Modal.Title as="h3">{INDICATOR_TEXT.TITLE_ACCOUNT}</Modal.Title>

          <button type="button" onClick={handleClose} className="icon-close" />
        </div>
        <div className="modal__body modal__body--form">
          <AccountSidebar />
          <div className="d-flex mt-5">
            <Button
              className="flex-fill"
              variant="primary"
              size="lg"
              style={{ cursor: 'pointer' }}
              onClick={handleLogout}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                    size="sm"
                  />
                  &nbsp; {INDICATOR_TEXT.LOGOUT}
                </Fragment>
              ) : (
                <Fragment>{INDICATOR_TEXT.LOGOUT}</Fragment>
              )}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(IndicatorMobile);
