import React, { memo, useCallback } from 'react';
import cn from 'classnames';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';
// import styled from 'styled-components';

// const Container = styled.div`
//   // width: 3rem;
//   // height: 3rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 0 0 auto;
//   cursor: pointer;
//   border-radius: 4px;
//   margin-right: 0.8rem;
//   &.has-bg {
//     background-color: #fff;
//     svg {
//       fill: var(--primary);
//     }
//   }

//   .icon {
//     fill: #fff;
//     width: 32px;
//     height: 32px;
//     margin-right: 8px;
//   }

//   .wrap {
//     display: flex;
//     flex-direction: column;
//     padding-top: 2px;

//     & > span {
//       font-size: 11px;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.45;
//     }

//     .text-icon {
//       font-size: 1rem;
//       font-weight: 500;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.54;
//       display: flex;
//       align-items: center;
//       & > svg {
//         position: relative;
//         top: 2px;
//         width: 8px;
//         height: 8px;
//         left: 2px;
//         fill: #fff;
//         transform: rotate(90deg);
//       }
//     }
//   }
// `;

interface MegaMenuIconType {
  isActive?: boolean;
  setIsActive?: (isActive: boolean, ms: number) => void;
  className?: string;
}

function MegaMenuIcon({
  isActive,
  setIsActive,
  className,
}: MegaMenuIconType): JSX.Element {
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  const handleMouseOver = (): void => {
    if (typeof setIsActive === 'function') {
      setIsActive(true, 100);
    }
  };

  const handleMouseOut = (): void => {
    if (typeof setIsActive === 'function') {
      setIsActive(false, 100);
    }
  };

  const handleOnClick = useCallback((): void => {
    if (typeof setIsActive === 'function') {
      setIsActive(!isActive, 0);
    }

    // window.location.href = '/danh-muc/sitemap';
  }, [isActive, setIsActive]);

  if (isMobile) {
    return (
      <div
        className={cn('mega-menu__icon', className, {
          'has-bg': isActive,
        })}
      >
        <a
          className={cn(EVENT_CLASS_NAME)}
          data-category={EVENT_CATEGORY.ACTION}
          data-action={EVENT_ACTION_NAME}
          data-label={EVENT_LABEL.MEGA_MENU_ICON}
          href="/danh-muc/sitemap"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            version="1.1"
            className="icon"
          >
            <path
              d="M9 2a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h5zm0 2H4v5h5V4zm11-2a2 2 0 012 2v5a2 2 0 01-2 2h-5a2 2 0 01-2-2V4a2 2 0 012-2h5zm0 2h-5v5h5V4zM9 13a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2v-5a2 2 0 012-2h5zm0 2H4v5h5v-5zm8.5-2a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"
              fillRule="nonzero"
            />
          </svg>
        </a>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleOnClick}
      className={cn('mega-menu__icon', className, {
        'has-bg': isActive,
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        version="1.1"
        className="icon"
      >
        <path
          d="M9 2a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h5zm0 2H4v5h5V4zm11-2a2 2 0 012 2v5a2 2 0 01-2 2h-5a2 2 0 01-2-2V4a2 2 0 012-2h5zm0 2h-5v5h5V4zM9 13a2 2 0 012 2v5a2 2 0 01-2 2H4a2 2 0 01-2-2v-5a2 2 0 012-2h5zm0 2H4v5h5v-5zm8.5-2a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a2.5 2.5 0 000 5 2.5 2.5 0 000-5z"
          fillRule="nonzero"
        />
      </svg>
      <div className="wrap">
        <span>Danh Mục</span>
        <span className="text-icon">
          Sản Phẩm
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="12"
            viewBox="0 0 10.5 14"
          >
            <path d="M7,0l7,10.5H0Z" transform="translate(10.5) rotate(90)" />
          </svg>
        </span>
      </div>
    </div>
  );
}

export default memo(MegaMenuIcon);
