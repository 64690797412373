import { useRef } from 'react';

export default function useLock<T>() {
  const waitList = useRef([]);
  const acquire = () => {
    return new Promise<T>((resolve) => {
      waitList.current.push(resolve);
    });
  };
  const release = (value: T) => {
    waitList.current.forEach((cb) => {
      cb(value);
    });
    waitList.current = [];
  };
  return {
    acquire,
    release,
  };
}
