import React, { FC, memo, useEffect } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import get from 'lodash/get';

import { Container, Badge } from 'react-bootstrap';
import LogoIcon from '@views/static/fast-light.svg';

import IndicatorCart from './component/indicator-cart';
import IndicatorMobile from './component/indicator-mobile';
import SearchForm from './component/search';
import { MegaMenuIcon, MegaMenuPanel } from './component/mega-menu';

import useMegaMenuState from './hooks/use-mega-menu';
import { useConfig } from '@server/lib/config';
import useLayoutContext from '@views/layouts/hooks/use-context';
// import useCategory from '@hooks/use-category';
import { useCategoryLazyQuery } from 'src/generated/graphql';

import { absoluteUrl, isServer } from '@lib/helpers';

const HeaderMain: FC<any> = memo(
  (props): JSX.Element => {
    const {
      authenticated,
      checkout: { data },
      activeCartToast,
      setActiveCartToast,
    } = useLayoutContext();

    const cart = get(data, 'checkout.cart', []);
    const quantity = cart?.reduce((node: number, { quantity }) => {
      return node + quantity || 0;
    }, 0);

    const { IS_BETA = false } = useConfig();
    const [isActive, setIsActive] = useMegaMenuState();
    const [
      loadCategory,
      { loading, error, data: categoryData, fetchMore },
    ] = useCategoryLazyQuery();

    // const dataCategory = useCategory();

    useEffect(() => {
      if (!isServer) {
        window.document.onreadystatechange = () => {
          if (document.readyState === 'complete') {
            loadCategory({
              variables: {
                page: 1,
                limit: 9999,
                orderBy: 'position_on_menu|asc',
              },
            });
          }
        };
      }
    }, []);

    const handleLoadMore = (parentId: any) => {
      // fetchMore({
      //   variables: {
      //     page: 1,
      //     limit: 1000,
      //     parentId: parseInt(parentId, 10),
      //   },
      //   updateQuery: (previousResult, { fetchMoreResult }) => {
      //     debugger;
      //     return {
      //       ...previousResult,
      //       matches: [
      //         ...previousResult.categories,
      //         ...fetchMoreResult.categories,
      //       ],
      //     };
      //   },
      // });
    };
    const categories = get(categoryData, 'categories', []);

    return (
      // <!-- Header Search -->
      <section className="navbar navbar--search">
        <Container style={{ position: 'relative' }}>
          <Link href="/">
            <a
              aria-label="Văn phòng phẩm FAST"
              title="Văn phòng phẩm FAST"
              className="navbar-brand navbar--brand"
            >
              <LogoIcon />
              {/* <img
                src={absoluteUrl('/assets/logo-autumn.png')}
                className="img-fluid loaded"
              /> */}
              {IS_BETA && <Badge>BETA</Badge>}
            </a>
          </Link>

          <IndicatorMobile authenticated={authenticated} quantity={quantity} />
          <SearchForm menuIcon={<MegaMenuIcon setIsActive={setIsActive} />}>
            <IndicatorCart
              hideOnMobile
              quantity={quantity}
              showToast={activeCartToast}
              onCloseToast={() => setActiveCartToast(false)}
            />
          </SearchForm>
          {categories && (
            <MegaMenuPanel
              // @ts-ignore
              categories={categories}
              loading={loading}
              error={error}
              setIsActive={setIsActive}
              className={cn({ 'd-none': !isActive })}
              handleLoadMore={handleLoadMore}
            />
          )}
        </Container>
      </section>
    );
  }
);

export default HeaderMain;
