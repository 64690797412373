import { useState, useEffect } from 'react';
import loadScript from '@helpers/load-script';
import removeScript from '@helpers/remove-script';

export interface FetchResponseSuccess {
  googleId: string;
  tokenObj: Object;
  tokenId: string;
  accessToken: string;
  profileObj: {
    googleId: string;
    imageUrl: string;
    email: string;
    name: string;
    givenName: string;
    familyName: string;
  };
};

const useGoogleLogin = ({
  onSuccess,
  clientId,
  cookiePolicy,
  loginHint,
  hostedDomain,
  autoLoad,
  isSignedIn,
  fetchBasicProfile,
  redirectUri,
  discoveryDocs,
  onFailure,
  onInitFailure,
  uxMode,
  scope,
  accessType,
  responseType,
  jsSrc,
  onRequest,
  prompt
}) => {
  const [loaded, setLoaded] = useState(false);

  function handleSigninSuccess(res: any) {
    /*
      offer renamed response keys to names that match use
    */
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse();
    res.googleId = basicProfile.getId();
    res.tokenObj = authResponse;
    res.tokenId = authResponse.id_token;
    res.accessToken = authResponse.access_token;
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName()
    };
    onSuccess(res as FetchResponseSuccess);
  }

  function signIn(event: any) {
    if (event) {
      event.preventDefault(); // to prevent submit if used within form
    }
    if (loaded) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      const options = {
        prompt
      };
      onRequest();
      if (responseType === 'code') {
        auth2.grantOfflineAccess(options).then((res: any) => onSuccess(res), (err: any) => onFailure(err));
      } else {
        auth2.signIn(options).then((res: any) => handleSigninSuccess(res), (err: any) => onFailure(err));
      }
    }
  }

  useEffect(() => {
    
    loadScript('google-login', jsSrc, () => {
      const params = {
        client_id: clientId,
        cookie_policy: cookiePolicy,
        login_hint: loginHint,
        hosted_domain: hostedDomain,
        fetch_basic_profile: fetchBasicProfile,
        discoveryDocs,
        ux_mode: uxMode,
        redirect_uri: redirectUri,
        scope,
        access_type: accessType
      };

      if (responseType === 'code') {
        params.access_type = 'offline';
      }

      if (typeof window !== undefined && !!window['gapi']) {
        window.gapi.load('auth2', () => {
          if (!window.gapi.auth2.getAuthInstance()) {
            window.gapi.auth2.init(params).then(
              (res: any) => {
                setLoaded(true);
                if (isSignedIn && res.isSignedIn.get()) {
                  handleSigninSuccess(res.currentUser.get());
                }
              },
              (err: any) => onInitFailure(err)
            )
          } else {
            setLoaded(true);
          }
        });
      }
    });

    return () => {
      removeScript('google-login');
    }
  }, []);

  useEffect(() => {
    if (autoLoad) {
      signIn(null);
    }
  }, [loaded]);

  return [ loaded, signIn ];
}

export default useGoogleLogin;