import { MutableRefObject, useRef, useState } from 'react';

function useMegaMenuState(): [
  boolean,
  (isActive: boolean, ms: number) => void
] {
  const [isOpenMegaMenu, setIsOpenMegaMenu] = useState(false);

  const timeoutRef: MutableRefObject<any> = useRef(null);

  const handleOpenMegaMenu = (ms: number): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (!isOpenMegaMenu) {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        setIsOpenMegaMenu(true);
      }, ms);
    }
  };

  const handleCloseMegaMenu = (ms: number): void => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    if (isOpenMegaMenu) {
      timeoutRef.current = setTimeout(() => {
        setIsOpenMegaMenu(false);
      }, ms);
    }
  };

  const setIsActive = (isActive: boolean, ms: number): void => {
    if (isActive) {
      handleOpenMegaMenu(ms);
    } else {
      handleCloseMegaMenu(ms);
    }
  };

  return [isOpenMegaMenu, setIsActive];
}

export default useMegaMenuState;
