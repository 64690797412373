import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const useLogoutMutation = () => {
  return useMutation(LOGOUT);
};
