import React, { FunctionComponent, memo } from 'react';
import HeaderTopAuth from './components/header-auth';
import HeaderNotAuth from './components/header-not-auth';
import useLayoutContext from '@views/layouts/hooks/use-context';

import { ApolloConsumer } from '@lib/apollo/apollo-consumer';
import { registerResolver } from './data/auth-form.data';

const HeaderTop: FunctionComponent = () => {
  const { me } = useLayoutContext();

  if (me.size) {
    return <HeaderTopAuth me={me} />;
  }

  return (
    <ApolloConsumer registerResolver={registerResolver}>
      <HeaderNotAuth />
    </ApolloConsumer>
  );
};

export default memo(HeaderTop);
