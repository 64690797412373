export const getId = (url: string | string[]): number => {
  if (Array.isArray(url)) {
    url = url.join('');
  }

  const [_, id] = (url || '').split(/(\d+)*(\.html)/);
  if (id !== undefined) {
    return parseInt(id);
  }

  return 0;
};

export default getId;
