import React, { FunctionComponent, MouseEvent } from 'react';
import { ModalTitle } from 'react-bootstrap';

type TTitleProps = {
  title: string;
  onClose: (event: MouseEvent<HTMLButtonElement>) => void;
};

export const Title: FunctionComponent<TTitleProps> = ({ title, onClose }) => (
  <div className="d-flex justify-content-between">
    <ModalTitle as="h3" className="mb-4">
      {title}
    </ModalTitle>
    <button
      type="button"
      className="icon-close"
      data-dismiss="modal"
      aria-label="Close"
      onClick={onClose}
    />
  </div>
);

export default Title;
