import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TProductSidebarProps = TParentComponentProps & {};

export const ProductSidebar: FunctionComponent<TProductSidebarProps> = (
  props
) => {
  return <ParentComponent {...props} />;
};

ProductSidebar.defaultProps = {
  defaultClassName: 'product__sidebar',
};

export default ProductSidebar;
