import gql from 'graphql-tag';

import { useMutation } from '@apollo/react-hooks';
export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

export const APPLY_RESET_PASSWORD = gql`
  mutation ApplyToResetPassword($token: String!, $password: String!) {
    applyTpResetPassword(token: $token, password: $password)
  }
`;

export const useApplyToResetPassword = () => {
  return useMutation(APPLY_RESET_PASSWORD);
};

export const useResetPassword = () => {
  return useMutation(RESET_PASSWORD);
};
