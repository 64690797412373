import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const CardInfo: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

CardInfo.defaultProps = {
  defaultClassName: 'product-card__info',
};

export default CardInfo;
