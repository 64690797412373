import { useState } from 'react';
import { MainLayoutContextValue } from '../context';
import { useMeQuery } from 'src/generated/graphql';
import { fromJS } from 'immutable';
import get from 'lodash/get';

// import createCartContext from './create-cart-context';
import createCheckoutContext from './create-checkout-context';

export default function createMainContext(): MainLayoutContextValue {
  const [activeCartToast, setActiveCartToast] = useState(false);
  const [overlay, setOverlay] = useState(false);

  const { data, loading, error, refetch: fetchMe } = useMeQuery({
    ssr: false,
    onCompleted: () => {},
    onError: () => {},
  });

  const immutableMe = fromJS(get(data, 'me', {}) || {});
  const authenticated = immutableMe.size > 0;

  return {
    me: immutableMe,
    authenticated,
    loading,
    error: !!error,
    fetchMe,
    // cart: createCartContext(),
    checkout: createCheckoutContext({ authenticated }),
    activeCartToast,
    setActiveCartToast,
    overlay,
    setOverlay,
  };
}
