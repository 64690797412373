import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockOverview: FunctionComponent<TParentComponentProps> = (
  props
) => {
  return <ParentComponent {...props} />;
};

BlockOverview.defaultProps = {
  defaultClassName: 'block__overview',
};

export default BlockOverview;
