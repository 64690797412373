import React, { Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import BlockSidebarBody from './BlockSidebarBody';
import BlockSidebarHeader from './BlockSidebarHeader';
import BlockSidebarItem from './BlockSidebarItem';

export class BlockSidebar extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'block-sidebar',
  };
  static Header = BlockSidebarHeader;
  static Body = BlockSidebarBody;
  static Item = BlockSidebarItem;

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}
export default BlockSidebar;
