import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TDividerProps = TParentComponentProps & {
  block?: boolean;
};

export const Divider: FunctionComponent<TDividerProps> = ({
  block,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'divider--block': block,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

Divider.defaultProps = {
  defaultClassName: 'divider',
  block: true,
};

export default Divider;
