import React, { FunctionComponent, Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';

const BlockAddressTitle: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

BlockAddressTitle.defaultProps = {
  defaultClassName: 'block-address__title',
};

const BlockAddressText: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

BlockAddressText.defaultProps = {
  defaultClassName: 'block-address__text',
};

export type TBlockAddressProps = TParentComponentProps & {};

export default class BlockAddress extends Component<TBlockAddressProps> {
  static defaultProps = {
    defaultClassName: 'block-address',
  };

  static Title = BlockAddressTitle;
  static Text = BlockAddressText;

  render(): JSX.Element {
    const { className, ...otherProps } = this.props;

    const cx = classNames({
      [className]: !!className,
    });

    return <ParentComponent className={cx} {...otherProps} />;
  }
}
