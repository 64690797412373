export const EVENT_CLASS_NAME = 'SendEvent';
export const EVENT_ACTION_NAME = 'click';
export enum EVENT_CATEGORY {
  PRODUCT = 'product',
  PRODUCT_HOMEPAGE = 'product_homepage',
  PRODUCT_RECOMMEND_HOMEPAGE = 'product_recommend_homepage',
  PRODUCT_INTERESTED = 'product_interested',
  PRODUCT_DETAIL_SAME_CATEGORY = 'product_same_category',
  PRODUCT_RECENTLY = 'product_recently',
  PRODUCT_CATEGORY = 'product_category',
  PRODUCT_BRAND = 'product_brand',
  PRODUCT_SEARCH = 'product_search',
  PRODUCT_RECOMMEND_SEARCH = 'product_recommend_search',
  PRODUCT_QUOTATION = 'product_quotation',
  PRODUCT_QUOTATION_ADD_CART = 'product_quotation_add_to_cart',
  CATEGORY = 'category',
  CATEGORY_HOMEPAGE = 'category_homepage',
  CATEGORY_MAIN_MENU = 'category_main_menu',
  CATEGORY_CHILD_MENU = 'category_child_menu',
  CATEGORY_PRODUCT_DETAIL = 'category_product_detail',
  CATEGORY_CHILD_DETAIL = 'category_child_detail',
  CATEGORY_TOP_SEARCH = 'category_top_search',
  BANNER = 'banner',
  ACTION = 'action',
  ACTION_HELPER = 'action_helper',
  PARTNER = 'partner',
  LOYALTY_CUSTOMER = 'loyalty_customer',
  BREADCRUMB = 'breadcrumb',
  BRAND = 'brand',
  SELECT_GIFT = 'select_gift',
  DELETE_GIFT = 'delete_gift',
  ACTION_CHECKOUT = 'action_checkout',
  TOP_SUGGEST_KEYWORD = 'top_suggest_keyword',
  GA_SUGGEST_KEYWORD = 'ga_suggest_keyword',
  QUOTATION_FORM = 'quotation_form',
}

export enum EVENT_LABEL {
  HOMEPAGE = 'homepage',
  RECENT_PRODUCT = 'recent_product',
  HOT_PRODUCT_CATEGORY = 'hot_product_category',
  HOT_PRODUCT_MORE_ACTION = 'hot_product_category_more_action',
  FEATURES_HOMEPAGE_ICON = 'features_homepage_icon',
  MAIN_HOMEPAGE_BANNER = 'main_homepage_banner',
  SUB_HOMEPAGE_BANNER = 'sub_homepage_banner',
  MAIN_HOMEPAGE_BANNER_MOBILE = 'main_homepage_banner_mobile',
  SUB_HOMEPAGE_BANNER_MOBILE = 'sub_homepage_banner_mobile',
  STICKY_HOMEPAGE_BANNER = 'sticky_homepage_banner',
  PRODUCT_DETAIL_BANNER = 'main_homepage_banner',
  ACTION_TOP = 'action_top',
  ACTION_CART_DESKTOP = 'action_cart_desktop',
  ACTION_CART_MOBILE = 'action_cart_mobile',
  ACTION_PROFILE_MOBILE = 'action_profile_mobile',
  MEGA_MENU_ICON = 'mega_menu_icon',
  MEGA_MENU_MAIN_CATEGORY = 'mega_menu_main_category',
  MEGA_MENU_CHILD_CATEGORY = 'mega_menu_child_category',
  HOT_PHONE_ACTION = 'hot_phone_action',
  CHAT_ACTION = 'chat_action',
  STICKY_HELPER_ACTION = 'sticky_helper_action',
  CHOOSE_QUOTATION_FILE = 'choose_quotation_file',
  SUBMIT_QUOTATION_FORM = 'SUBMIT_QUOTATION_FORM',
}
