import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TProductInfoProps = TParentComponentProps & {};

export const ProductBody: FunctionComponent<TProductInfoProps> = (props) => {
  return <ParentComponent {...props} />;
};

ProductBody.defaultProps = {
  defaultClassName: 'product__body',
};

export default ProductBody;
