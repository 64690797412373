export const loadScript = (id: string, jsSrc: string, cb?: () => void) => {
  const headArray = document.getElementsByTagName('head');
  if (headArray && headArray.length > 0) {
    let js = document.createElement('script');
    js.id = id;
    js.src = jsSrc;
    js.onload = cb;
    headArray[0].appendChild(js);
  }
};

export default loadScript;
