import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const CardBody: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

CardBody.defaultProps = {
  defaultClassName: 'product-card__body',
};

export default CardBody;
