export const getKey = (key: string, initialValue: any) => {
  if (typeof window === 'undefined') {
    return initialValue;
  }

  try {
    // Get from local storage by key
    const item = localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // If error also return initialValue
    console.log(error);
    return initialValue;
  }
};

export const setKey = (key: string, value: any) => {
  try {
    // Save to local storage
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // A more advanced implementation would handle the error case
    console.log(error);
  }
};
