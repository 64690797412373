import gql from 'graphql-tag';
import User from '../fragments/user.gql';
export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const LOGIN = gql`
  mutation Login(
    $email: String!
    $password: String!
    $cartItems: [AddCartMultipleItem]
  ) {
    login(email: $email, password: $password, cartItems: $cartItems) {
      ...User
    }
  }

  ${User}
`;

export const REGISTER = gql`
  mutation Register(
    $name: String!
    $email: String!
    $password: String!
    $recaptchaResponse: String
    $recaptchaType: String
  ) {
    register(
      name: $name
      email: $email
      password: $password
      recaptchaResponse: $recaptchaResponse
      recaptchaType: $recaptchaType
    ) {
      ...User
    }
  }
  ${User}
`;
