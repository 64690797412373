import gql from 'graphql-tag';
import { fromJS, Record } from 'immutable';
import User from '@graphql/fragments/user.gql';
import { useQuery } from '@apollo/react-hooks';
import { IUser } from '@server/data/models/user';

interface Auth {
  loading: boolean;
  error: boolean;
  me: Record<IUser | null>;
}

interface Options {
  notifyOnNetworkStatusChange?: boolean;
  ssr?: boolean;
}

const defaultOption: Options = {
  notifyOnNetworkStatusChange: true,
  ssr: false,
};

export const ME = gql`
  query Me {
    me {
      ...User
    }
  }
  ${User}
`;

export const useAuth = (options: Options = defaultOption) => {
  const { data, error, loading, refetch } = useQuery(ME, options);

  const me = !!data && !!data.me ? fromJS(data.me) : fromJS({});

  return {
    error: !!error,
    me,
    loading,
    refetch,
  };
};
