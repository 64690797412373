import React, {
  PropsWithChildren,
  memo,
  ReactNode,
  useRef,
  useState,
  useEffect,
} from 'react';
// import { Button } from 'react-bootstrap';
import { useRouter } from 'next/router';
import { stringify } from 'query-string';
import _trim from 'lodash/trim';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _arrayToString from '@helpers/array-to-string';
import _scrollTop from '@helpers/scroll-top';
import {
  useSearchKeywordLazyQuery,
  useSearchSuggestKeywordsQuery,
  useProductWithReviewQuery,
} from 'src/generated/graphql';
import useOnClickOutside from '@hooks/use-on-click-outside';
import { useLayoutContext } from '@views/layouts';

import LazyImage from '@views/components/lazy-image';
import { Formik, Form } from 'formik';
import * as S from './styles';
import SuggestCategory from './suggest_category';

import { INTERNAL_ROUTES as ROUTES } from '@helpers/route';
import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

import _getId from '@helpers/get-id-from-slug';

export type InitialValues = {
  keyword: string;
};

type SearchProps = {
  menuIcon: ReactNode;
};

const getTitleName = (slug: string) => {
  switch (slug) {
    case '/danh-muc': {
      return 'Danh mục';
    }
    case '/san-pham': {
      return 'Sản phẩm';
    }
    case '/bai-viet': {
      return 'Bài viết';
    }
    default:
      return '';
  }
};

const LIMIT_KEYWORD = 12;

export const Search = memo<PropsWithChildren<SearchProps>>(
  ({ menuIcon, children }) => {
    const searchRef = useRef();
    const router = useRouter();
    const { setOverlay } = useLayoutContext();
    const initValues: InitialValues = {
      keyword: decodeURIComponent(_arrayToString(router.query.q || '')),
    };

    let timeout: any;
    const [pattern, setPattern] = useState(_get(initValues, 'keyword'));
    const onChange = (text: string) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setPattern(text);
      }, 750);
    };

    const [
      searchKeyword,
      { data, loading, error },
    ] = useSearchKeywordLazyQuery();

    const suggestKeywordFilters: {
      [key: string]: any;
    } = {
      keyword: '',
      status: 1,
      page: 1,
      limit: LIMIT_KEYWORD,
    };

    if (router.pathname.includes(ROUTES.CATEGORY)) {
      const categoryId = _getId(router.query.slug);
      suggestKeywordFilters.categoryIds = [categoryId];
    }

    if (router.pathname.includes(ROUTES.PRODUCT)) {
      const productId = _getId(router.query.slug);
      const { data: productData } = useProductWithReviewQuery({
        fetchPolicy: 'cache-only',
        variables: { id: productId, limit: 12, offset: 0 },
      });
      if (productData?.data?.product?.categories) {
        suggestKeywordFilters.categoryIds = productData.data.product.categories.map(
          ({ id }) => parseInt(id, 10)
        );
      }
    }

    // if (router.query.categoryIds && Array.isArray(router.query.categoryIds)) {
    //   suggestKeywordFilters.categoryIds = router.query.categoryIds.map((id) =>
    //     parseInt(id, 10)
    //   );
    // }

    const { data: suggestKeywords } = useSearchSuggestKeywordsQuery({
      variables: {
        filter: suggestKeywordFilters,
      },
      // ssr: false,
    });

    const [isSuggestModalOpen, setSuggestModalOpen] = useState(false);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(searchRef, () => {
      setSuggestModalOpen(false);
      setOverlay(false);
    });

    useEffect(() => {
      if (pattern) {
        searchKeyword({
          variables: {
            pattern,
            limit: 8,
          },
        });
      }
    }, [pattern]);

    const onSubmit = (values: InitialValues, { setSubmitting }) => {
      const query = {
        q: _trim(values.keyword),
      };
      const queryEncode = encodeURI(stringify(query, { encode: true }));
      const uri = `${ROUTES.SEARCH}?${queryEncode}`;
      router.push(
        {
          pathname: ROUTES.SEARCH,
          query,
        },
        uri
      );
      setSuggestModalOpen(false);
      setOverlay(false);
      setSubmitting(false);
      _scrollTop();
    };

    const keywords = _get(data, 'data', []);
    const recommend = [];
    const unique = [];
    if (Array.isArray(keywords)) {
      keywords.forEach((item) => {
        if (!unique.includes(_get(item, 'item.slug'))) {
          recommend.push(item);
          unique.push(_get(item, 'item.slug'));
        }
      });
    }

    return (
      <Formik initialValues={initValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, handleSubmit }) => (
          <Form className="header-search__form" onSubmit={handleSubmit}>
            {menuIcon}
            <S.Wrapper
              ref={searchRef}
              className="header-search__form-group mr-sm-2"
              hasFocus={isSuggestModalOpen}
            >
              <S.SearchButton
                type="submit"
                className="header-search__button"
                aria-label="search"
              />
              <S.Input
                id="keyword-search-box"
                aria-label="Từ khóa"
                type="input"
                name="keyword"
                value={values.keyword}
                className="header-search__input"
                placeholder="Nhập tên sản phẩm cần tìm"
                onChange={(e) => {
                  setFieldValue('keyword', e.target.value);
                  onChange(e.target.value);
                }}
                onFocus={() => {
                  setSuggestModalOpen(true);
                  setOverlay(true);
                }}
                autoComplete="off"
              />
              <label htmlFor="#keyword-search-box" />

              <S.QuickLink>
                {suggestKeywords?.data?.map((item, index) => {
                  const encode = encodeURI(
                    stringify(
                      {
                        q: item.keyword,
                      },
                      { encode: true }
                    )
                  );
                  const url =
                    item.url &&
                    (item.url.includes('https://') ||
                      item.url.includes('http://'))
                      ? item.url
                      : `${ROUTES.SEARCH}?${encode}`;

                  return (
                    <a
                      key={index}
                      href={url}
                      aria-label={item.keyword}
                      title={item.keyword}
                      className={cn('search-item', EVENT_CLASS_NAME)}
                      data-category={EVENT_CATEGORY.TOP_SUGGEST_KEYWORD}
                      data-action={EVENT_ACTION_NAME}
                      data-label={item.keyword}
                    >
                      <span className="keyword">{item.keyword}</span>
                    </a>
                  );
                })}
              </S.QuickLink>

              {isSuggestModalOpen && (
                <S.SearchAutocomplete>
                  {(pattern === '' || !keywords) && (
                    <>
                      <S.SearchList>
                        <S.Header>
                          <img
                            src="/assets/icon-trending.png"
                            className="item-icon"
                          />
                          <span>Tìm Kiếm Phổ Biến</span>
                        </S.Header>
                        <S.TopKeyword>
                          {suggestKeywords?.data?.map((item, index) => {
                            const encode = encodeURI(
                              stringify(
                                {
                                  q: item.keyword,
                                },
                                { encode: true }
                              )
                            );

                            const url =
                              item.url &&
                              (item.url.includes('https://') ||
                                item.url.includes('http://'))
                                ? item.url
                                : `${ROUTES.SEARCH}?${encode}`;

                            return (
                              <a
                                key={index}
                                href={url}
                                aria-label={item.keyword}
                                title={item.keyword}
                                className={cn('search-item', EVENT_CLASS_NAME)}
                                data-category={
                                  EVENT_CATEGORY.TOP_SUGGEST_KEYWORD
                                }
                                data-action={EVENT_ACTION_NAME}
                                data-label={item.keyword}
                              >
                                <LazyImage
                                  src={item.image || '/assets/icon-search.png'}
                                  alt={item.keyword}
                                  className="item-icon"
                                />
                                <span className="keyword">{item.keyword}</span>
                              </a>
                            );
                          })}
                        </S.TopKeyword>
                      </S.SearchList>
                      <SuggestCategory />
                    </>
                  )}
                  <S.SearchList>
                    {Array.isArray(keywords) &&
                      [...keywords].map((item, index) => {
                        const encode = encodeURI(
                          stringify(
                            {
                              q: _get(item, 'item.keyword'),
                            },
                            { encode: true }
                          )
                        );

                        const url =
                          _get(item, 'item.link') ||
                          `${ROUTES.SEARCH}?${encode}`;

                        return (
                          <a
                            key={index}
                            href={url}
                            aria-label={_get(item, 'item.keyword')}
                            title={_get(item, 'item.keyword')}
                            className={cn('search-item', EVENT_CLASS_NAME)}
                            data-category={EVENT_CATEGORY.GA_SUGGEST_KEYWORD}
                            data-action={EVENT_ACTION_NAME}
                            data-label={_get(item, 'item.keyword')}
                          >
                            <img
                              src="/assets/icon-search.png"
                              className="item-icon"
                            />
                            <span className="keyword">
                              {_get(item, 'item.keyword')}
                            </span>
                          </a>
                        );
                      })}

                    {recommend.map((item, index) => (
                      <a
                        key={index}
                        href={_get(item, 'item.link')}
                        aria-label={_get(item, 'item.keyword')}
                        title={_get(item, 'item.keyword')}
                        className={cn('search-item', EVENT_CLASS_NAME)}
                        data-category={EVENT_CATEGORY.GA_SUGGEST_KEYWORD}
                        data-action={EVENT_ACTION_NAME}
                        data-label={_get(item, 'item.keyword')}
                      >
                        <img
                          src="/assets/icon-search.png"
                          className="item-icon"
                        />
                        <div className="info">
                          <span className="title-prefix">
                            {getTitleName(_get(item, 'item.slug'))}
                          </span>
                          <span className="title">
                            {_get(item, 'item.title')}
                          </span>
                        </div>
                      </a>
                    ))}
                  </S.SearchList>
                </S.SearchAutocomplete>
              )}
            </S.Wrapper>
            {children}
          </Form>
        )}
      </Formik>
    );
  }
);

export default Search;
