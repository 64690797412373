import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TBlockHtmlProps = TParentComponentProps & {};
export const BlockHtml: FunctionComponent<TBlockHtmlProps> = (props) => {
  return <ParentComponent {...props} />;
};

BlockHtml.defaultProps = {
  defaultClassName: 'block__html',
};

export default BlockHtml;
