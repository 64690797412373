import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TBlockAccountProps = TParentComponentProps & {
  inForm?: boolean;
};

export const BlockAccount: FunctionComponent<TBlockAccountProps> = ({
  inForm,
  className,
  ...otherProps
}) => {
  const cx = classNames({
    'block-account--in-form': inForm,
    [className]: !!className,
  });

  return <ParentComponent className={cx} {...otherProps} />;
};

BlockAccount.defaultProps = {
  defaultClassName: 'block-account',
};

export default BlockAccount;
