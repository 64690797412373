import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import classNames from 'classnames';

export type Props = {
  as?: any;
  width?: string;
  height?: string;
  widthRandomness?: number;
  heightRandomness?: number;
  borderRadius?: string;
  color?: string;
  count?: number;
  animated?: boolean;
};

const Progress = keyframes`
  0% {
    left: -500px;
  }
  100% {
    left: 100%;
  }
`;

const SkeletonLoad = styled.span.attrs((props) => ({
  style: {
    backgroundColor: props.backgroundColor,
    width: props.width,
    height: props.height,
    borderRadius: props.borderRadius,
  },
}))`
  line-height: 1;
  display: inline-block;
  overflow: hidden;
  position: relative;
  &.animated::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 500px;
    top: 0;
    left: -500px;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0)
    );
    animation: ${Progress} 1.2s ease-in-out infinite;
  }
`;

const Skeleton: FunctionComponent<Props> = (props) => {
  const w = parseFloat(props.width);
  const h = parseFloat(props.height);
  const wm = props.width.toString().replace(/\d+/g, '');
  const hm = props.height.toString().replace(/\d+/g, '');
  const { borderRadius, color } = props;
  const elements = [];
  const cx = classNames({
    animated: props.animated,
  });
  const { as: Component = 'span' } = props;

  for (let i = 0; i < props.count; i++) {
    const width = `${w - Math.random() * w * props.widthRandomness}${wm}`;
    const height = `${h - Math.random() * h * props.heightRandomness}${hm}`;
    const otherProps = {
      width,
      height,
      borderRadius,
      backgroundColor: color,
    };

    elements.push(
      <SkeletonLoad key={i} className={cx} {...otherProps}>
        &zwnj;
      </SkeletonLoad>
    );

    if (i !== props.count - 1) {
      elements.push(<br key={`br-${i}`} />);
    }
  }

  return <Component>{elements}</Component>;
};

Skeleton.defaultProps = {
  width: '150px',
  height: '100%',
  widthRandomness: 0.25,
  heightRandomness: 0,
  borderRadius: '2px',
  color: '#f4f4f4',
  count: 1,
  animated: true,
};

export default Skeleton;
