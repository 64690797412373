import React, { Component, FunctionComponent } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

interface IIndicatorCartProps {
  isHideMobile: boolean;
  numberItem: number;
  children?: React.ReactNode;
}

interface ICartBadge {
  number: number;
}

const CartBadge: FunctionComponent<ICartBadge> = ({
  number: number,
}): JSX.Element => <span className="indicator__badge">{number}</span>;

export class IndicatorCart extends Component<IIndicatorCartProps> {
  static defaultProps: Partial<IIndicatorCartProps> = {
    isHideMobile: false,
  };

  render(): JSX.Element {
    const { isHideMobile, numberItem } = this.props;
    const indicatorClassName: string = classNames({
      indicator__button: true,
      'd-none': true,
      'd-md-block': isHideMobile,
    });

    return (
      <div className="indicator">
        <Link href="/gio-hang">
          <a className={indicatorClassName}>
            <span className="indicator__area">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 23.077"
                className="indicator__icon indicator__icon--cart"
              >
                <g transform="translate(-111.5 -32.5)">
                  <circle
                    cx="1.582"
                    cy="1.582"
                    r="1.582"
                    transform="translate(121.46 52.413)"
                    fill="#fff"
                  />
                  <circle
                    cx="1.582"
                    cy="1.582"
                    r="1.582"
                    transform="translate(134.72 52.413)"
                    fill="#fff"
                  />
                  <path
                    d="M141.241,36.379a1.082,1.082,0,0,0-.822-.378h-21.4l-.51-2.629a1.081,1.081,0,0,0-1.062-.873h-4.865a1.077,1.077,0,1,0,0,2.155h3.973l1.272,6.558,1.525,9.5a1.081,1.081,0,0,0,1.068.907h17.838a1.081,1.081,0,0,0,1.068-.907l2.162-13.466A1.076,1.076,0,0,0,141.241,36.379Zm-3.906,13.088H121.341l-1.816-11.312h19.626Z"
                    transform="translate(0)"
                    fill="#fff"
                  />
                </g>
              </svg>
              {!!numberItem && <CartBadge number={numberItem} />}
            </span>
          </a>
        </Link>
      </div>
    );
  }
}

export default IndicatorCart;
