export enum FormType {
  Login = 1,
  Signup = 2,
  Forgot = 3,
  Message = 4
};

export type GetAuthFormProps = {
  show: boolean;
  type: FormType | null;
  __typename?: string
};