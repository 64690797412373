import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const CardAction: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

CardAction.defaultProps = {
  defaultClassName: 'product-card__action',
  as: 'div',
};

export default CardAction;
