import convertToUrl from './convert-to-url';

export enum INTERNAL_ROUTES {
  HOME = '/',
  ACCOUNT = '/tai-khoan',
  MANAGE_ADDRESS = '/quan-ly-dia-chi',
  MANAGE_ORDER = '/quan-ly-don-hang',
  NOTIFICATION = '/thong-bao',
  CART = '/gio-hang',
  CHECKOUT = '/thanh-toan',
  CHECKOUT_DONE = '/hoan-tat-thanh-toan',
  CATEGORY = '/danh-muc',
  PRODUCT = '/san-pham',
  SEARCH = '/tim-kiem',
  CHECKING_ORDER = '/tra-cuu-don-hang',
  RECENT_PRODUCT = '/danh-sach-da-xem',
  INTEREST_PRODUCT = '/danh-sach-yeu-thich',
  CONTACT = '/lien-he',
  BRAND = '/thuong-hieu',
  QUOTATION = '/bao-gia',
}

export enum INTERNAL_URI {
  PRODUCT = '/san-pham/[slug]',
  CATEGORY = '/danh-muc/[slug]',
  ORDER_DETAIL = '/quan-ly-don-hang/[id]',
  BRAND = '/thuong-hieu/[slug]',
}

export const friendlyUri = (id: string | number, name: string): string => {
  return `${convertToUrl(name)}-${id}`;
};

export const productHref = (id: string | number, name: string): string => {
  return `${INTERNAL_ROUTES.PRODUCT}/${friendlyUri(id, name)}.html`;
};

export const categoryHref = (id: string | number, name: string): string => {
  return `${INTERNAL_ROUTES.CATEGORY}/${friendlyUri(id, name)}.html`;
};

export const orderDetailHref = (id: string | number): string => {
  return `${INTERNAL_ROUTES.MANAGE_ORDER}/${id}`;
};

export const brandHref = (id: string | number, name: string): string => {
  return `${INTERNAL_ROUTES.BRAND}/${friendlyUri(id, name)}.html`;
};
