import React, { ReactNode, FunctionComponent } from 'react';
import classNames from 'classnames';

export type TRatingBodyProps = {
  className?: string;
  children?: ReactNode;
};

export const RatingBody: FunctionComponent<TRatingBodyProps> = ({
  className = '',
  children = null,
}) => (
  <div
    className={classNames({
      rating__body: true,
      [className]: !!className,
    })}
  >
    {children}
  </div>
);

export default RatingBody;
