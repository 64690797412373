import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
} from 'react';

import { useRouter } from 'next/router';
import classNames from 'classnames';

import { INDICATOR_TEXT } from '../../intl';
import { INTERNAL_ROUTES as ROUTES } from '@helpers/route';
import IconCheck from '@views/static/icon-check.svg';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

const CartToast: FunctionComponent<any> = ({ onClose }): JSX.Element => {
  const router = useRouter();
  const handleClose = useCallback((event) => {
    onClose();
  }, []);

  const gotoCartPage = useCallback(async (event) => {
    await onClose();
    router.push(ROUTES.CART);
  }, []);

  return (
    <div className="indicator__dropdown">
      <div className="dropcart">
        <button type="button" onClick={handleClose} className="icon-close" />
        {/* @TODO: Show list of product in cart */}

        {/* <div className="dropcart-product__list">
          <div className="dropcart-product__item">
            <div className="dropcart-product__image">

            </div>
            <div className="dropcart-product__info">

            </div>
          </div>
        </div> */}

        <div className="dropcart__message">
          <IconCheck width="1em" height="1em" />
          <span>{INDICATOR_TEXT.ADD_TO_CART_SUCCESS}</span>
        </div>
        <div className="dropcart__button">
          <a className="btn" onClick={gotoCartPage}>
            {INDICATOR_TEXT.VIEW_CART}
          </a>
        </div>
      </div>
    </div>
  );
};

export type TIndicatorCartProps = PropsWithChildren<{
  quantity?: number;
  hide?: boolean;
  hideOnMobile?: boolean;
  showToast?: boolean;
  onCloseToast?: () => void;
  dataLabel?: string;
}>;

export const IndicatorCart: FunctionComponent<TIndicatorCartProps> = ({
  quantity = 0,
  hide = true,
  hideOnMobile = false,
  showToast = false,
  onCloseToast,
  dataLabel = EVENT_LABEL.ACTION_CART_DESKTOP,
}): JSX.Element => {
  const indicatorClassName: string = classNames({
    indicator__button: true,
  });

  const parentClassName: string = classNames({
    indicator: true,
    'indicator--cart': true,
    'indicator--open': showToast,
    'd-none': hide,
    'd-md-block': hideOnMobile,
  });

  return (
    <div className={parentClassName}>
      <a
        className={cn(indicatorClassName, EVENT_CLASS_NAME)}
        data-category={EVENT_CATEGORY.ACTION}
        data-action={EVENT_ACTION_NAME}
        data-label={dataLabel}
        href={ROUTES.CART}
      >
        <span className="indicator__area">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 23.077"
            className="indicator__icon indicator__icon--cart"
          >
            <g transform="translate(-111.5 -32.5)">
              <circle
                cx="1.582"
                cy="1.582"
                r="1.582"
                transform="translate(121.46 52.413)"
                fill="#fff"
              />
              <circle
                cx="1.582"
                cy="1.582"
                r="1.582"
                transform="translate(134.72 52.413)"
                fill="#fff"
              />
              <path
                d="M141.241,36.379a1.082,1.082,0,0,0-.822-.378h-21.4l-.51-2.629a1.081,1.081,0,0,0-1.062-.873h-4.865a1.077,1.077,0,1,0,0,2.155h3.973l1.272,6.558,1.525,9.5a1.081,1.081,0,0,0,1.068.907h17.838a1.081,1.081,0,0,0,1.068-.907l2.162-13.466A1.076,1.076,0,0,0,141.241,36.379Zm-3.906,13.088H121.341l-1.816-11.312h19.626Z"
                transform="translate(0)"
                fill="#fff"
              />
            </g>
          </svg>
          <span className="indicator__badge">{quantity}</span>
        </span>
      </a>

      <CartToast onClose={onCloseToast} />
    </div>
  );
};

export default IndicatorCart;
