import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import Head from 'next/head';

import { Container } from 'react-bootstrap';
import Header from '@views/modules/header';
import Footer from './components/footer/dynamic';
import Widget from './components/widget/dynamic';
import Overlay from './components/overlay';
// import Snowflake from './components/snowflake';

import { MainLayoutContext } from './context';
import createMainContext from './helpers/create-main-context';

type MainLayoutProps = {
  title?: string;
  breadcrumb?: ReactNode;
  beforeBody?: ReactNode;
  afterBody?: ReactNode;
  children?: ReactNode;
};

export const MainLayout: FunctionComponent<MainLayoutProps> = ({
  title,
  breadcrumb,
  beforeBody,
  afterBody,
  children,
}) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {/* {typeof window !== 'undefined' && (
          <link
            rel="canonical"
            href={`${window.location.protocol}//${window.location.host}${window.location.pathname}`}
          />
        )} */}
      </Head>

      <MainLayoutContext.Provider value={createMainContext()}>
        <div className="vpp-wrapper">
          <Header />
          {breadcrumb}
          {beforeBody}
          <main className="body">
            <Container>{children}</Container>
          </main>
          {afterBody}
          <Footer />
          <Overlay />
        </div>

        <Widget />
      </MainLayoutContext.Provider>
      {/* <Snowflake /> */}
    </>
  );
};

export const MobileMainLayout: FunctionComponent<MainLayoutProps> = ({
  children,
}) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {/* {typeof window !== 'undefined' && (
          <link
            rel="canonical"
            href={`${window.location.protocol}//${window.location.host}${window.location.pathname}`}
          />
        )} */}
      </Head>

      <MainLayoutContext.Provider value={createMainContext()}>
        <div className="vpp-wrapper">
          <Header />
          {children}
          <Overlay />
        </div>
      </MainLayoutContext.Provider>
    </>
  );
};

export default MainLayout;
