import React, { memo, useState, useMemo, useEffect } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import _get from 'lodash/get';
import _isUndefined from 'lodash/isUndefined';

import MainCategory from './main-category';
import SubCategory from './sub-category';

import { ICategory } from '@server/data/models/category';

const mapSubCategory = {};
const remapData = (data: ICategory[]) => {
  const mainData = data.filter((item) => _get(item, 'parentId') == 1);
  mainData.forEach((parent) => {
    const categoryId = _get(parent, 'id');
    // if (_isUndefined(mapSubCategory[categoryId])) {
    mapSubCategory[categoryId] = [];
    data
      .filter((child) => _get(child, 'parentId') == categoryId)
      .forEach((child) => {
        mapSubCategory[categoryId].push({
          root: child,
          items: data.filter(
            (nextChild) => _get(nextChild, 'parentId') == _get(child, 'id')
          ),
        });
      });
    // }
  });

  return { mainData, mapSubData: mapSubCategory };
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 4.8rem;
  z-index: 999;
  margin: 0 24px;
  // padding: 12px 24px;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  &.has-sub {
    right: 0;
  }
`;

const WrapperSubCategory = styled.div`
  border-left: 1px solid #eee;
  padding: 0;
  display: flex;
  flex: 1 1;
  width: 100%;
  max-height: 515px;
  overflow-y: auto;
`;

const WrapperMainCategory = styled.div`
  padding: 0;
  flex: 0 0 300px;
  width: 300px;
  max-height: 515px;
  overflow-y: auto;
`;

type MegaMenuPanelTypeProps = React.PropsWithChildren<{
  setIsActive: (isActive: boolean, ms: number) => void;
  handleLoadMore?: (id: number) => void;
  loading: boolean;
  error: any;
  categories: ICategory[];
  className: string;
}>;

const UNDEFINED_ID = 999;
const LOADED_ACTIVE_ID = [UNDEFINED_ID];

const MegaMenuPanel: React.FC<MegaMenuPanelTypeProps> = (
  _props
): JSX.Element => {
  const {
    setIsActive,
    loading,
    error,
    categories,
    className,
    handleLoadMore,
  } = _props;
  const [activeId, setActiveId] = useState(UNDEFINED_ID);

  // useEffect(() => {
  //   if (!LOADED_ACTIVE_ID.includes(activeId) && handleLoadMore) {
  //     handleLoadMore(activeId);
  //     LOADED_ACTIVE_ID.push(activeId);
  //   }
  // }, [activeId, LOADED_ACTIVE_ID]);

  const handleMouseOver = (): void => {
    setIsActive(true, 0);
  };
  const handleMouseOut = (): void => {
    setIsActive(false, 0);
  };

  // if (loading || error) {
  //   return null;
  // }

  const haveSub = activeId !== UNDEFINED_ID;
  const { mainData, mapSubData } = useMemo(() => remapData(categories), [
    categories,
  ]);
  // console.log(`mapSubData[${activeId}]`, mapSubData[activeId]);

  return (
    <Container
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      className={cn(className, { 'has-sub': haveSub })}
    >
      <WrapperMainCategory>
        <MainCategory
          data={mainData}
          activeId={activeId}
          setActiveId={setActiveId}
        />
      </WrapperMainCategory>
      {haveSub && (
        <WrapperSubCategory>
          <SubCategory data={mapSubData[activeId]} />
        </WrapperSubCategory>
      )}
    </Container>
  );
};

export default memo(MegaMenuPanel);
