import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockProductBody: FunctionComponent<TParentComponentProps> = (
  props
) => <ParentComponent {...props} />;

BlockProductBody.defaultProps = {
  defaultClassName: 'block-product__body',
};

export default BlockProductBody;
