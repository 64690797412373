import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import cn from 'classnames';
import { ICategory } from '@server/data/models/category';
import _get from 'lodash/get';
import _truncate from 'lodash/truncate';
import { categoryHref, INTERNAL_URI } from '@helpers/route';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  width: 100%;
`;

const WrapperCol = styled.div`
  display: inline-flex;
  width: ${(props) => (props.less ? '50%' : '33.33%')};
  flex: 0 0 ${(props) => (props.less ? '50%' : '33.33%')};
  align-items: flex-start;
  flex-direction: column;
  overflow: hidden;
  & > a {
    color: #000;
    padding: 4px 8px 4px 18px;
    &:first-child {
      font-weight: bold;
      padding-left: 4px;
    }
    // font-weight: 500;
    flex: 0 0 100%;
    width: 100%;
    border-radius: 4px;
    svg {
      fill: var(--primary);
    }
    &:hover {
      background-color: #eee;
      // color: var(--white) !important;
    }
    &.text-bold {
      font-weight: 600;
    }
  }
`;

export type Props = {
  data: Array<{
    root: ICategory;
    items: ICategory[];
  }>;
};

const SubCategory = React.memo<Props>(({ data }) => {
  const less = data.length < 10;

  return (
    <Wrapper>
      {data.map(({ root, items }, index) => {
        return (
          <WrapperCol less={less} key={`${_get(root, 'id')}_${index}`} xs={4}>
            <Link href={categoryHref(_get(root, 'id'), _get(root, 'urlKey'))}>
              <a
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.CATEGORY_CHILD_MENU}
                data-action={EVENT_ACTION_NAME}
                data-label={_get(root, 'name')}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                >
                  <path
                    fillRule="nonzero"
                    d="M13.214 12L8 6.476 9.393 5 16 12l-6.607 7L8 17.524z"
                  />
                </svg>{' '}
                {_truncate(_get(root, 'name'), { length: 50 })}
              </a>
            </Link>
            {items.map((item) => (
              <Link
                key={_get(item, 'id')}
                href={categoryHref(_get(item, 'id'), _get(item, 'urlKey'))}
              >
                <a
                  className={cn(EVENT_CLASS_NAME)}
                  data-category={EVENT_CATEGORY.CATEGORY_CHILD_MENU}
                  data-action={EVENT_ACTION_NAME}
                  data-label={_get(item, 'name')}
                >
                  {_truncate(_get(item, 'name'), { length: 50 })}
                </a>
              </Link>
            ))}
          </WrapperCol>
        );
      })}
    </Wrapper>
  );
});

export default SubCategory;
