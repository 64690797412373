import React, { Component } from 'react';
import classNames from 'classnames';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import ProductContent from './ProductContent';
import ProductGallery from './ProductGallery';
import ProductBody from './ProductBody';
import ProductInfo from './ProductInfo';
import ProductName from './ProductName';
import ProductRating from './ProductRating';
import ProductPrice from './ProductPrice';
import ProductSidebar from './ProductSidebar';
import ProductPlaceholder from './ProductPlaceholder';
import ProductAction from './ProductAction';

export type TProductProps = TParentComponentProps & {
  standard?: boolean;
  modal?: boolean;
};

export class Product extends Component<TProductProps> {
  static Body = ProductBody;
  static Content = ProductContent;
  static Gallery = ProductGallery;
  static Info = ProductInfo;
  static Name = ProductName;
  static Rating = ProductRating;
  static Price = ProductPrice;
  static Sidebar = ProductSidebar;
  static Placeholder = ProductPlaceholder;
  static Action = ProductAction;
  static defaultProps = {
    defaultClassName: 'product',
    standard: false,
  };

  render(): JSX.Element {
    const { className, standard, modal, ...otherProps } = this.props;
    const blockClassName = classNames({
      'product--layout-standard': standard,
      'product--layout-modal': modal,
      [className]: !!className,
    });

    return <ParentComponent className={blockClassName} {...otherProps} />;
  }
}

export default Product;
export { default as ProductCard } from './card/Card';
export {
  default as ProductCardPrice,
  CardNewPrice as ProductCardNewPrice,
  CardBadgeSale as ProductCardBadgeSale,
  CardOldPrice as ProductCardOldPrice,
} from './card/CardPrice';
export { default as ProductCardText } from './card/CardText';
export { default as ProductCardRating } from './card/CardRating';
export { default as ProductCardBody } from './card/CardBody';
export { default as ProductCardInfo } from './card/CardInfo';
export { default as ProductCardImage } from './card/CardImage';
export { default as ProductCardSkeleton } from './card/CardSkeleton';
