import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockSidebarItem: FunctionComponent<TParentComponentProps> = (
  props
) => {
  return <ParentComponent {...props} />;
};

BlockSidebarItem.defaultProps = {
  defaultClassName: 'block-sidebar__item',
};

export default BlockSidebarItem;
