import gql from 'graphql-tag';

export const GET_AUTH_FORM_QUERY = gql`
  query GetAuthForm {
    getAuthForm @client {
      type
      show
    }
  }
`;

export const SET_AUTH_FORM_QUERY = gql`
  mutation SetAuthForm($show: Boolen, $type: Number) {
    setAuthForm(show: $show, type: $type) @client 
  }
`;