import React from 'react';
import { BlockSidebar } from '@views/components/block/Block';
import Skeleton from '@views/components/common/Skeleton';
import styled from 'styled-components';

const BlockSidebarSkeletonWrapper = styled.div`
  padding: 0 1.425rem 0.5rem;
  .block-sidebar {
    background-color: #fff;
    padding: 1rem;
  }
  .block-sidebar__header {
    padding: 0;
  }
`;

export const BlockSidebarPlaceholder = () => (
  <BlockSidebarSkeletonWrapper>
    <BlockSidebar>
      <BlockSidebar.Header>
        <Skeleton height="20px" width="100%" widthRandomness={0} />
      </BlockSidebar.Header>
      <BlockSidebar.Body>
        <Skeleton height="10px" width="100%" widthRandomness={0.5} count={30} />
      </BlockSidebar.Body>
    </BlockSidebar>
  </BlockSidebarSkeletonWrapper>
);

export default BlockSidebarPlaceholder;
