import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const BlockBody: FunctionComponent<TParentComponentProps> = (props) => {
  return <ParentComponent {...props} />;
};

BlockBody.defaultProps = {
  defaultClassName: 'block__body',
};

export default BlockBody;
