import React, { useRef, useCallback, useMemo } from 'react';
import GoogleRecaptcha, {
  ReCAPTCHAProps,
  ReCAPTCHA,
} from 'react-google-recaptcha';

const APP_GOOGLE_CAPTCHA_PUBLIC_KEY = process.env.CAPCHA_SITE_KEY || null;

import useLock from './use-lock';

const defaultOptions: ReCAPTCHAProps = {
  sitekey: APP_GOOGLE_CAPTCHA_PUBLIC_KEY,
  size: 'invisible',
  hl: 'vi',
  badge: 'inline',
};

let initListenerExecuted = false;
let onCloseCallbacks: Function[] = [];

function initListener(cb: Function) {
  onCloseCallbacks.push(cb);
  if (initListenerExecuted) {
    return;
  }
  initListenerExecuted = true;

  //@ts-ignore
  HTMLCollection.prototype.forEach = Array.prototype.forEach;
  document
    .getElementsByTagName('iframe')
    //@ts-ignore
    .forEach(iframe => {
      if (
        iframe.src &&
        iframe.src.includes('google.com/recaptcha/api2/bframe')
      ) {
        const recaptchaWindow = iframe.parentNode.parentNode;
        new MutationObserver(x => {
          if (recaptchaWindow.style.visibility === 'hidden') {
            onCloseCallbacks.forEach(cb => cb());
            onCloseCallbacks = [];
          }
        }).observe(recaptchaWindow, {
          attributes: true,
          attributeOldValue: true,
          attributeFilter: ['style'],
        });
      }
    });
}

export default function useGoogleRecaptcha(
  _options: Partial<ReCAPTCHAProps> = {},
): [React.ReactNode, () => Promise<string>] {
  const options: ReCAPTCHAProps = Object.assign({}, defaultOptions, _options);

  const reCaptchaRef = useRef<ReCAPTCHA>();
  const lockRef = useLock<string>();

  const getTokenAsync = useCallback(async (): Promise<string> => {
    try {
      const reCaptcha = reCaptchaRef.current;
      if (reCaptcha !== undefined) {
        const tokenLock = lockRef.acquire();
        initListener(() => {
          lockRef.release(null);
        });
        reCaptcha.execute();
        const token = await tokenLock;
        reCaptcha.reset();
        return token;
      }
    } catch (err) {
      console.log(err);
      return Promise.resolve(null);
    }
    return Promise.resolve(null);
  }, []);

  const reCaptcha = useMemo(
    () => (
      <GoogleRecaptcha
        {...options}
        ref={reCaptchaRef}
        onChange={(token: string | null) => lockRef.release(token)}
      />
    ),
    [],
  );
  return [reCaptcha, getTokenAsync];
}
