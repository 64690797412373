import React, { Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export class ProductActionItem extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product__action-item',
  };

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export class ProductAction extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'product__action',
  };
  static Item = ProductActionItem;

  render(): JSX.Element {
    return <ParentComponent {...this.props} />;
  }
}

export default ProductAction;
