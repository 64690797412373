import React, { FunctionComponent, useState } from 'react';
import { Carousel, Card } from 'react-bootstrap';
import classNames from 'classnames';
import LazyImage from '@views/components/lazy-image';
export type TProductGalleryProps = {
  data: string[];
};

export const ProductGallery: FunctionComponent<TProductGalleryProps> = ({
  data,
}) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="product__gallery">
      <Carousel
        activeIndex={index}
        indicators={false}
        controls={false}
        onSelect={handleSelect}
        className="product-carousel"
        interval={5000}
      >
        {data.map((item, i) => (
          <Carousel.Item
            key={`${item}-${i}`}
            className="product__carousel-item"
          >
            <LazyImage className="d-block w-100" src={item} alt={item} />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="carousel product-carousel">
        <ol className="carousel-indicators">
          {data.map((item, i) => (
            <li
              key={`${item}-${i}`}
              onClick={(event) => handleSelect(i, event)}
              className={classNames({ active: index === i })}
            >
              <Card>
                <LazyImage className="card-img-top" src={item} alt={item} />
              </Card>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
ProductGallery.defaultProps = {
  data: [],
};

export default ProductGallery;
