export const MODAL_FORM = {
  REQUIRED: 'Vui lòng điền vào mục này.',
  EMAIL_INVALID: 'Địa chỉ email không hợp lệ.',
  PASSWORD_REQUIRED:
    'Mật khẩu trên 8 ký tự (tối đa 32 ký tự). Bao gồm tối thiểu ký tự đặc biệt.',
  PASSWORD_CONFIRMATION_REQUIRED: 'Mật khẩu không trùng khớp.',
  NAME: 'Họ tên',
  EMAIL: 'Email',
  PASSWORD: 'Mật khẩu',
  PASSWORD_CONFIRMATION: 'Xác nhận mật khẩu',
  LOGIN: 'Đăng nhập',
  SIGN_UP: 'Đăng ký',
  SUBMITTING: 'Đang gửi',
  FORGET_PASSWORD: 'Cài đặt lại mật khẩu',
  HAS_ACCOUNT_YET: 'Bạn chưa có tài khoản?',
  HAS_ACCOUNT_ALREADY: 'Bạn đã có tài khoản?',
  WRONG_LOGIN: 'Email hoặc mật khẩu của bạn không đúng. Xin vui lòng thử lại.',
  WRONG_SIGN_UP: 'Thật tiếc, đã có lỗi xảy ra. Xin vui lòng thử lại.',
  WELCOME_MESSAGE: 'Xin chúc mừng bạn.',
  SIGN_UP_SUCCESS_MESSAGE: 'Tài khoản của bạn đã đăng ký thành công.',
  LOGIN_FAILED: 'Không đăng nhập thành công. Vui lòng thử lại',
  RESET_PASSWORD_MESSAGE: 'Cài đặt lại mật khẩu.',
  RESET_PASSWORD_SUCCESS_MESSAGE:
    'Một tin nhắn đã được gửi cho bạn qua email với các hướng dẫn về cách cài đặt lại mật khẩu của bạn.',
};

export const WARNING_CART_TEXT = {
  TITLE: 'Lưu ý:',
  MESSAGE: (
    <p className="mb-0">
      Nếu bạn bạn đã thêm sản phẩm vào giỏ hàng, giỏ hàng của bạn có thể bị thay
      đổi khi đăng nhập. Hãy đăng nhập trước khi bạn cần thêm sản phẩm vào giỏ
      hàng hoặc thực hiện Mua Nhanh. Hệ thống chúng tôi sẽ phục vụ bạn tốt hơn.
    </p>
  ),
};

export const HEADER = {
  CHECKING: 'Kiểm tra đơn hàng',
  NOTIFICATION: 'Thông báo',
  HELP: 'Trợ giúp',
  LOGIN: 'ĐĂNG NHẬP',
  SIGN_UP: 'ĐĂNG KÝ',
  LOGOUT: 'Đăng xuất',
  MY_ACCOUNT: 'Tài khoản của tôi',
  ORDER: 'Đơn hàng',
  INTEREST_PRODUCT: 'Sản phẩm yêu thích',
  PROMOTION: 'Chính sách ưu đãi',
  SHARE: 'Blogs',
};
