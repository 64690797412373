import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const CardImage: FunctionComponent<TParentComponentProps> = (props) => (
  <ParentComponent {...props} />
);

CardImage.defaultProps = {
  defaultClassName: 'product-card__image',
};

export default CardImage;
