import {
  useFacebookLogin,
  IProps,
  IFBResponse,
  FBResponseStatus,
} from './use-facebook-login';
import { FunctionComponent } from 'react';

export type Props = IProps & {
  render?: Function;
};

export const FacebookLogin: FunctionComponent<Props> = ({
  render,
  ...rest
}) => {
  if (!render) {
    return null;
  }

  const [loaded, signIn] = useFacebookLogin(rest as IProps);

  return render({ onClick: signIn });
};

export default FacebookLogin;
