import React, { FunctionComponent } from 'react';
import BlockProduct from './BlockProduct';
import { ProductCardSkeleton } from '@views/components/product/Product';

export type TBlockProductListSkeletonProps = {
  number?: number;
};

export const BlockProductListSkeleton: FunctionComponent<TBlockProductListSkeletonProps> = ({
  number,
}) => (
  <>
    {Array.from({ length: number }, (_, i) => Symbol(i).toString()).map(
      (_, key) => (
        <BlockProduct.Item key={key}>
          <ProductCardSkeleton />
        </BlockProduct.Item>
      )
    )}
  </>
);

BlockProductListSkeleton.defaultProps = {
  number: 12,
};

export default BlockProductListSkeleton;
