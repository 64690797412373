import React, { FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export type TProductNameProps = TParentComponentProps & {};

export const ProductName: FunctionComponent<TProductNameProps> = (props) => {
  return <ParentComponent {...props} />;
};

ProductName.defaultProps = {
  defaultClassName: 'product__name',
};

export default ProductName;
