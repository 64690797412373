import React, { ReactNode, Component, FunctionComponent } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import {
  Rating,
  RatingBody,
  RatingItem,
} from '@views/components/rating/Rating';

export type TProductCardRatingProps = {
  className?: string;
  children?: ReactNode;
};

export type TProductCardRatingLegendProps = {
  children?: ReactNode;
};

export const CardRatingLegend: FunctionComponent<TProductCardRatingLegendProps> = ({
  children,
}) => <div className="product-card__rating-legend">{children}</div>;

export class CardRating extends Component<TParentComponentProps> {
  static Wrapper = Rating;
  static Body = RatingBody;
  static Item = RatingItem;
  static Legend = CardRatingLegend;
  static defaultProps = {
    defaultClassName: 'product-card__rating',
  };

  render(): JSX.Element {
    const { children, ...otherProps } = this.props;
    return <ParentComponent {...otherProps}>{children}</ParentComponent>;
  }
}

export default CardRating;
