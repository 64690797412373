import React, { useState, Fragment, memo } from 'react';

import { Spinner } from 'react-bootstrap';
import { useAlert } from 'react-alert';
import Divider from '@views/components/divider';

import { ZaloLogin } from './zalo-login';
import { GoogleLogin } from './google-login';
import {
  IFBResponse,
  FBResponseStatus,
} from '@views/modules/facebook/use-facebook-login';
import FacebookLogin from '@views/modules/facebook/facebook-login';

import useLayoutContext from '@views/layouts/hooks/use-context';
import { useLoginSocialMutation } from '../hooks/use-login';
import { ME } from '@views/modules/header/hooks/use-auth';
import { MODAL_FORM } from '../intl';
import { useConfig } from '@server/lib/config';
import _get from 'lodash/get';

import FacebookIcon from '@views/static/facebook.svg';
import GoogleIcon from '@views/static/google.svg';
import ZaloIcon from '@views/static/zalo.svg';

enum SocialLoginType {
  Facebook = 'facebook',
  Google = 'google',
  Zalo = 'zalo',
}

const SocialLogin = ({ onClose }) => {
  const alert = useAlert();
  const {
    checkout: { data, handleRefetchCart, handleCleanCart },
  } = useLayoutContext();

  let cartItems = null;
  const cart = _get(data, 'checkout.cart', []);
  if (Array.isArray(cart) && cart.length > 0) {
    cartItems = cart.map((item) => ({
      id: _get(item, 'id', 0),
      quantity: _get(item, 'quantity', 0),
    }));
  }

  const {
    GOOGLE_APP_ID,
    LOGIN_SOCIAL,
    FACEBOOK_APP_ID,
    REDIRECT_URI,
    ZALO_APP_ID,
    ENABLED_FACEBOOK,
    ENABLED_GOOGLE,
  } = useConfig();
  const [fetchLoginSocial] = useLoginSocialMutation();

  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [type, setType] = useState<SocialLoginType>(null);

  const checkFacebookLoginState = async (response: IFBResponse) => {
    if (response.status !== FBResponseStatus.Connected) {
      alert.error(MODAL_FORM.LOGIN_FAILED);
      setSubmitting(false);
      setType(null);

      return;
    }

    try {
      await fetchLoginSocial({
        variables: {
          accessToken: _get(response, 'authResponse.accessToken'),
          type: SocialLoginType.Facebook,
          cartItems,
        },
        update: async (cache: any, { data: { loginSocial } }) => {
          await handleRefetchCart();

          cache.writeQuery({
            query: ME,
            data: { me: loginSocial },
          });

          handleCleanCart();
          onClose();
        },
      });
    } catch (error) {
      alert.error(
        _get(error, 'graphQLErrors[0].message', MODAL_FORM.LOGIN_FAILED)
      );
      setSubmitting(false);
      setType(null);
    }
  };

  const onGoogleLoginSuccess = async (response: any) => {
    try {
      await fetchLoginSocial({
        variables: {
          accessToken: _get(response, 'accessToken'),
          type: SocialLoginType.Google,
          cartItems,
        },
        update: async (cache: any, { data: { loginSocial } }) => {
          await handleRefetchCart();

          cache.writeQuery({
            query: ME,
            data: { me: loginSocial },
          });

          handleCleanCart();
          onClose();
        },
      });
    } catch (error) {
      alert.error(
        _get(error, 'graphQLErrors[0].message', MODAL_FORM.LOGIN_FAILED)
      );
      setSubmitting(false);
      setType(null);
    }
  };

  const onGoogleLoginFailure = (error: any) => {
    alert.error(MODAL_FORM.LOGIN_FAILED);
    setSubmitting(false);
    setType(null);
    console.log(error);
  };

  const onGoogleLoginInitFailure = (error: any) => {
    console.log(error);
  };

  const onZaloLoginSuccess = async (accessToken: string) => {
    try {
      await fetchLoginSocial({
        variables: {
          accessToken,
          type: SocialLoginType.Zalo,
          cartItems,
        },
        update: async (cache: any, { data: { loginSocial } }) => {
          await handleRefetchCart();

          cache.writeQuery({
            query: ME,
            data: { me: loginSocial },
          });

          handleCleanCart();
          onClose();
        },
      });
    } catch (error) {
      alert.error(
        _get(error, 'graphQLErrors[0].message', MODAL_FORM.LOGIN_FAILED)
      );
      setSubmitting(false);
      setType(null);
    }
  };

  if (!LOGIN_SOCIAL) {
    return null;
  }

  return (
    <Fragment>
      <Divider>Hoặc</Divider>
      <div className="social-login">
        {ENABLED_FACEBOOK && (
          <FacebookLogin
            xfbml
            cookie
            customerChat
            version="v3.2"
            scope="public_profile,email"
            appId={FACEBOOK_APP_ID}
            onResponse={checkFacebookLoginState}
            redirectUri={REDIRECT_URI}
            render={({ onClick }) => (
              <button
                type="button"
                className="social-login__icon facebook"
                onClick={onClick}
                disabled={isSubmitting}
              >
                {isSubmitting && type === SocialLoginType.Facebook ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <FacebookIcon />
                )}
              </button>
            )}
          />
        )}

        {ENABLED_GOOGLE && (
          <GoogleLogin
            onSuccess={onGoogleLoginSuccess}
            onFailure={onGoogleLoginFailure}
            onInitFailure={onGoogleLoginInitFailure}
            render={({ onClick }) => (
              <button
                type="button"
                className="social-login__icon google"
                onClick={(event) => {
                  setSubmitting(true);
                  setType(SocialLoginType.Google);
                  onClick(event);
                }}
                disabled={isSubmitting}
              >
                {isSubmitting && type === SocialLoginType.Google ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <GoogleIcon />
                )}
              </button>
            )}
            clientId={GOOGLE_APP_ID}
            cookiePolicy="single_host_origin"
            scope="profile email"
            jsSrc="https://apis.google.com/js/platform.js"
          />
        )}

        {/* <ZaloLogin
          appId={ZALO_APP_ID}
          onResponse={onZaloLoginSuccess}
          redirectUrl={REDIRECT_URI}
          render={({ onClick }) => (
            <button 
              type="button" 
              className="social-login__icon zalo"
              onClick={onClick} 
              disabled={isSubmitting}
            >
              {isSubmitting && type === SocialLoginType.Facebook ? (
                <Spinner
                  as="span"
                  animation="grow"
                  role="status"
                  aria-hidden="true"
                />
              ): (
                <ZaloIcon/>
              )}
            </button>
          )}
        /> */}
      </div>
    </Fragment>
  );
};

export default memo(SocialLogin);
